
import Cookies from 'js-cookie';

export const storeToken = (token) => {
  // Store token in cookies with a 3-hour expiration
  Cookies.set('authTokenUser', token, { expires: 0.125 }); // 0.125 days = 3 hours
};

export const getToken = () => {
  return Cookies.get('authTokenUser');
};

export const removeToken = () => {
  // Remove the token from cookies
  Cookies.remove('authTokenUser');
};


export const authTokenUser = () => {
  Cookies.get("authTokenUser");
};

export const isTokenExpired = () => {
  const token = getToken();
  // If the token is not found, it's considered expired
  if (!token) {
    return true;
  }
  // You can implement additional logic here if needed to check the token's expiration based on its content.
  return false;
};






