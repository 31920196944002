import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import Seo from "../../Components/SeoComponent/Seo";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import { fetchProfile, verifyAadharWithOtp, generateAadharOtp } from "../../Services/ApiTestService/aadharTestService";
import { InputWithLabel, Button } from "../../Components/Tags/Tags";
import Loader from "../../Loader";
import {Data} from './Data';

const TestAadhar = () => {
  const [aadharNo, setAadharNo] = useState("");
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [aadharDetails, setAadharDetails] = useState({});
  const [otpSent, setOtpSent] = useState(false); // Track if OTP is sent
  const [clientId, setClientId] = useState('');

  // Fetch Profile Data on Mount
  useEffect(() => {
    async function fetchProfileData() {
      try {
        setIsLoading(true);
        const response = await fetchProfile(); // API call to get profile details
        setProfileData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching profile data", error);
        showErrorToast("Failed to fetch profile data.");
        setIsLoading(false);
      }
    }
    fetchProfileData();
  }, []);

  // Validate Aadhar Number and OTP
  const validateAadhar = () => {
    const errors = {};
    if (!aadharNo || aadharNo.length !== 12 || isNaN(aadharNo)) {
      errors.aadharNo = "Please enter a valid 12-digit Aadhar number.";
      showErrorToast(errors.aadharNo = "Please enter a valid 12-digit Aadhar number.")
    }
    if (otpSent && (!otp || otp.length !== 6 || isNaN(otp))) {
      errors.otp = "Please enter a valid 6-digit OTP.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Submit Aadhar Verification
  const handleSubmit = async () => {
    if (!validateAadhar()) return;

    try {
      setIsLoading(true);
      const response = await verifyAadharWithOtp({
        api_id: profileData.user_id,
        api_key: profileData.api_key,
        token_id: profileData.token_id,
        methodName: "validateotp",
        aadhar: aadharNo,
        otp: otp,
        client_id: clientId,
      });

      setAadharDetails(response?.data?.data || {});
     // console.log(aadharDetails);
      showSuccessToast("Aadhar Verified Successfully.");
      setAadharNo('');
      setOtpSent(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error verifying Aadhar:", error);
      showErrorToast("Failed to verify Aadhar.");
      setIsLoading(false);
    }
  };

  // Handle Aadhar number submission and OTP visibility
  const handleAadharSubmit = async () => {
    if (!validateAadhar()) return;

    try {
      setIsLoading(true);
     const response = await generateAadharOtp({
        api_id: profileData?.user_id,
        api_key: profileData?.api_key,
        token_id: profileData?.token_id,
        methodName: "getotp",
        aadhar: aadharNo,
      });
      if(response.status.code === 500){
        setIsLoading(false);
        showErrorToast(response.message || "Failed to generate OTP!")
      } 
      if(response.status.code === 200){
      setIsLoading(false);
      setClientId(response?.data?.data?.client_id)
      setOtpSent(true); // OTP is sent, allow the user to input OTP
      
      showSuccessToast(response.message || "OTP sent successfully, please enter OTP.");
      
      return;
     }
    } catch (error) {
      showErrorToast("Failed to send OTP.");
      setIsLoading(false);
    }
  };

  return (
    <>
      <Seo />
      {isLoading && <Loader />}
      <Header heading="Test Aadhar API" />

      <div className="grid grid-cols-2 md:grid-cols-6 gap-3 justify-center md:ml-64 ml-0">
        <InputWithLabel
          type="text"
          label="Aadhar No"
          placeholder="Enter Aadhar No"
          maxLength={12} // Limit input to 12 digits
          className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
          name="aadharNo"
          id="aadharNo"
          required={true}
          value={aadharNo}
          onChange={(e) => setAadharNo(e.target.value.replace(/\D/g, ""))} // Prevent non-numeric input
          error={errors.aadharNo}
        />

        {/* Verify button for Aadhar No */}
        <div>
          <Button
            type="button"
            className="text-center text-white bg-blue-900 shadow-lg bg-gradient-to-r from-blue-400 via-blue-1200 to-blue-900 font-semibold rounded-md px-2 py-1 mt-5 text-md hover:from-blue-900 hover:to-blue-400 border-4 ms-2"
            id="verifyAadhar"
            name="verifyAadhar"
            text="Verify Aadhar"
            onClick={handleAadharSubmit} // Call generate OTP API
          />
        </div>

        {/* OTP input and submit button will only show after OTP is sent */}
        {otpSent && (
          <>
            <InputWithLabel
              type="text"
              label="OTP"
              placeholder="Enter OTP"
              maxLength={6} // Limit OTP to 6 digits
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600 w-24"
              name="otp"
              id="otp"
              required={true}
              value={otp}
              onChange={(e) => setOtp(e.target.value.replace(/\D/g, ""))} // Prevent non-numeric input
              error={errors.otp}
            />

            <div>
              <Button
                type="button"
                className="text-center text-white bg-blue-900 shadow-lg bg-gradient-to-r from-blue-400 via-blue-1200 to-blue-900 font-semibold rounded-md px-2 py-1 mt-5 text-md hover:from-blue-900 hover:to-blue-400 border-4 ms-2"
                id="submitOtp"
                name="submitOtp"
                text="Submit OTP"
                onClick={handleSubmit} // Call Aadhar verification with OTP
              />
            </div>
          </>
        )}
      </div>
      
   {/* Aadhaar Details Card */}
   {aadharDetails && Object.keys(aadharDetails).length > 0 && (

  <>
  <div className="flex justify-center">
    <h2 className="font-bold text-2xl mt-4">Aadhar Details</h2>
  </div>
  <div className="border border-gray-300 rounded-lg p-4 mt-1 flex justify-center shadow-inner shadow-blue-600">
    {/* Profile Photo */}
    
    <div className="w-[16%] md:w-[12%]">
    <img
  src={
    aadharDetails?.profile_image
      ? `data:image/jpeg;base64,${aadharDetails?.profile_image}`
      : "/path/to/default-placeholder.png"
  }
  alt="Aadhar"
  className="rounded-lg object-cover w-full h-auto"
/>

</div>

    {/* Aadhaar Details */}
    <div className="w-[70%] md:w-[70%] ml-16  grid grid-cols-2 text-lg gap-4 ">
      {/* Render Specific Details */}
      <div className="px-2">
        <span className="font-bold">Name:</span> {aadharDetails?.full_name || "N/A"}
      </div>
      <div className="px-2">
        <span className="font-bold">Aadhaar Number:</span>{" "}
        {aadharDetails?.aadhaar_number || "N/A"}
      </div>
      <div className="px-2">
  <span className="font-bold">Date of Birth:</span>{" "}
  {aadharDetails?.dob
    ? new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(new Date(aadharDetails?.dob))
    : "N/A"}
</div>

<div className="px-2">
  <span className="font-bold">Gender:</span> 
  {aadharDetails?.data?.data?.gender === 'M' ? 'Male' : 
   aadharDetails?.data?.data?.gender === 'F' ? 'Female' : 'N/A'}
</div>

      <div className="px-2">
      <span className="font-bold">Care Of:</span> {aadharDetails?.care_of || "N/A"}
     </div>
     <div className="px-2">
     {aadharDetails?.address ? (
     <>
    <div>
      <span className="font-bold">House:</span>{" "}
      {[
        aadharDetails?.address?.house,
      ]
        .filter(Boolean) 
        .join(", ")}
    </div>
  </>
) : (
  "N/A"
)}
     </div>
    <div className="col-span-2 shadow-inner px-2">
{aadharDetails?.address ? (
  <>
    <div>
      <span className="font-bold">Address:</span>{" "}
      {[
        aadharDetails?.address?.house,
        aadharDetails?.address?.street,
        aadharDetails?.address?.landmark,
        aadharDetails?.address?.loc,
        aadharDetails?.address?.dist,
        aadharDetails?.zip,
      ]
        .filter(Boolean) // Filter out undefined or null values
        .join(", ")}
    </div>
  </>
) : (
  "N/A"
)}
</div>


    </div>
  </div>
  </>
)}
      {/* API Response Card */}
      {aadharDetails && Object.keys(aadharDetails).length > 0 && (
        <div className="border border-gray-300 rounded-lg p-1 mt-2 shadow-inner shadow-blue-800">
          <h2 className="text-2xl font-bold mb-2 text-center">API Response</h2>
          <pre className="bg-slate-100 p-1 rounded-md overflow-auto text-green-600">
            {JSON.stringify(aadharDetails, null, 2)}
          </pre>
        </div>
      )}
    </>
  );
};

export default TestAadhar;  