import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineLine } from 'react-icons/ai';
import { verifyRoutePin } from '../../Services/RoutesServics/RoutesServics';
import { showErrorToast, showSuccessToast } from '../../utils/toastUtils';
import { fetchProfile ,profileImgPreview} from '../../Services/profile/profileservice';


const PinInput = ({ inputRefs, onPinChange }) => {
  const [pin, setPin] = useState(['', '', '', '', '', '']);

  const handleChange = (index, value) => {
    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);

    if (onPinChange) {
      onPinChange(newPin.join('')); // Pass the full PIN to onPinChange
    }

    // Focus next input if the value is entered
    if (value !== '' && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleBackspace = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && pin[index] === '') {
      inputRefs.current[index - 1]?.focus();
    }
  };

  return (
    <div className="flex justify-center mb-4">
      {pin.map((value, index) => (
        <React.Fragment key={index}>
          <input
            ref={(el) => (inputRefs.current[index] = el)}
            type="password"
            maxLength={1}
            value={value}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleBackspace(index, e)}
            className="border border-blue-500 md:w-10 md:h-10  w-6 h-7 text-center mx-1 rounded shadow-lg shadow-blue-600"
            autoFocus={index === 0}
          />
          {index === 2 && <span className="md:mt-2 text-sky-500 ml-1">&ndash;</span>}
        </React.Fragment>
      ))}
    </div>
  );
};

const LockScreen = () => {
  const inputRefs = useRef([]);
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();
const [profileImg, setProfileImg] = useState('');
const [filename,setFilename] = useState('');

  const handleSubmit = async () => {
    if (pin.length !== 6) {
      setError('Please enter a 6-digit PIN.');
      return;
    }

    try {
      const result = await verifyRoutePin(pin);
      if (result?.status?.code === 200) {
        // PIN verification successful
        showSuccessToast(result?.message || 'Access granted.');
        navigate('/dashboard'); // Redirect to the dashboard
      } else {
        // PIN verification failed
        const errorMsg = result?.message || 'Invalid PIN. Please try again.';
        showErrorToast(errorMsg);
        setError(errorMsg);
      }
    } catch (err) {
      showErrorToast('An error occurred. Please try again.');
      setError('An error occurred. Please try again.');
    }
  };


  useEffect(() => {
      const fetchProfileData = async () => {
          try {
              const response = await fetchProfile();
              setProfile(response.data);
             //console.log(response)
          } catch (error) {
              console.error('Error fetching profile:', error);
          }
      };
      fetchProfileData();
  }, []);
  

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (window.location.pathname !== "/lockscreen") {
        event.preventDefault();
        event.returnValue = ''; // Trigger confirmation dialog
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
  
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  





     // Preview Profile Image
                      useEffect(() => {
                          const PreviewImg = async () => {
                          //   if (!filename) {
                          //     console.warn('Filename is undefined or empty');
                          //     return;
                          //   }
                            try {
                              const response = await profileImgPreview({ filename });
                              setProfileImg(response.dataUri); 
                            } catch (error) {
                              console.error('Error fetching image:', error);
                            }
                          };
                          PreviewImg();  
                        }, [filename]);
                        // ENd


    useEffect(() => {
          const fetchProfileData = async () => {
              try {
                  const profileData = await fetchProfile();
                  setProfile(profileData.data);
                  setFilename(profileData.profile);
              } catch (error) {
                  console.error('Error fetching profile:', error);
              }
          };
  
          fetchProfileData();
      }, []);
  


  // Preview Profile Image
                    useEffect(() => {
                        const PreviewImg = async () => {
                        //   if (!filename) {
                        //     console.warn('Filename is undefined or empty');
                        //     return;
                        //   }
                          try {
                            const response = await profileImgPreview({ filename });
                            setProfileImg(response.dataUri); 
                          } catch (error) {
                            console.error('Error fetching image:', error);
                          }
                        };
                        PreviewImg();  
                      }, [filename]);
                      // ENd



  
  return (
    <div className="flex flex-col justify-center items-center w-full h-screen z-50 absolute top-0 left-0 backdrop-blur-10" style={{ backgroundImage: 'url(assets/images/cap2.jpg)' }}
    >
      <AiOutlineLine color="blue" size={30} />
      <div className="flex md:text-3xl text-2xl font-bold gap-2 text-blue-700">Hey {profile?.name}
                   <img
                        className='rounded-md md:h-12 md:w-12 w-7 h-7 mr-2'
                        src={profileImg}
                        alt="Profile" />

      </div>

      <div className="bg-white rounded-lg shadow-lg w-[70%] md:w-1/3 md:mt-2">
        <div className="bg-red-500 text-white text-center md:py-4 rounded-t-lg">
          <span className="md:text-xl text-md">Enter PIN to UnLock</span>
        </div>
        <div className="md:p-4 p-2">
          <PinInput inputRefs={inputRefs} onPinChange={setPin} />
          {error && <div className="text-red-500 md:mt-2 text-center">{error}</div>}
        </div>
        <div className="flex justify-center md:p-2 p-0">
          <button
            type="button"
            className="text-center text-white bg-blue-900 shadow-xl shadow-blue-600 bg-gradient-to-r from-blue-400 to-blue-900 
            font-semibold rounded-md md:px-4 md:py-2 text-sm p-1  md:text-lg hover:from-blue-900 hover:to-blue-400 border"
            onClick={handleSubmit}
          >
            Unlock
          </button>
        </div>
      </div>
      <p className="text-lg font-bold text-white md:mt-4 mt-1 animate-bounce">Unlock Using PIN</p>
    </div>
  );
};

export default LockScreen;
