import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import LoginUserPage from './Pages/LoginPages/LoginUserPage';
import ForgotPasswordPage from './Pages/LoginPages/ForgotPasswordPage';
import Auth2StepsPage from './Pages/LoginPages/Auth2StepsPage';
import ConfirmAuth2Steps from './Pages/LoginPages/ConfirmAuth2Steps';
import WhitelistIp from './Pages/ApiSetting/WhitelistIp';
import WhitelistYourBankAc from './Pages/MyAccount/WhitelistYourBankAc';
import BusinessWalletStructure from './Pages/MyAccount/BusinessWalletStructure';
import CommissionCharges from './Pages/ApiServices/CommissionCharges';
import LockScreen from './Pages/LoginPages/LockScreen';
import PayoutReport from './Pages/PayoutMenu/PayoutReport';
import ServicePage from './Pages/ApiServices/ServicePage';
import RechargeAndBBPSReport from './Pages/RechargeAndBBPS/RechargeAndBBPSReport';
import Dashboard from './Pages/DashboardMenu/Dashboard';
import ProfileApiUser from './Pages/MyAccount/ProfileApiUser';
import VanCollectionReport from './Pages/CollectMenu/VanCollectionReport';
import MainLayout from './Layouts/MainLayout';
import AuthWrapper from './Components/AuthWrapper';
import useInactivityLogout from './Services/InactiveLogout';
import './App.css';
import AddFund from './Pages/ApiServices/AddFund';
import ApiLink from './Pages/ApiServices/ApiLink';
import HelpDesk from './Pages/MyAccount/HelpDesk';
import { ProfileProvider } from './context/ProfileContext';
import Loader from './Loader';
import EquifaxCrScore from './Pages/EquifaxMenu/EquifaxCrScore/EquifaxCrScore';
import HighmarkCrScore from './Pages/EquifaxMenu/HighmarkCrScore/HighmarkCrScore';
import DiscriptionLink from './Pages/ApiServices/DiscriptionLink';
import RouteValidate from './Pages/RouteValidate/RouteValidate';
import TestAadhar from './Pages/TestAPI/TestAadhar';
import TestPan from './Pages/TestAPI/TestPan';
import Crif_EquifaxReport from './Pages/EquifaxMenu/EquifaxReport/Crif_EquifaxReport';

function App() {
  useInactivityLogout(900000); // Set the inactivity timeout to 10 minutes

  return (
    <Router>
      <RoutesWrapper>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<LoginUserPage />} />
          <Route path="/ForgotPasswordPage" element={<ForgotPasswordPage />} />
          <Route path="/Auth2StepsPage" element={<Auth2StepsPage />} />
          <Route path="/ConfirmAuth2Steps" element={<ConfirmAuth2Steps />} />

          {/* Protected Routes */}
          <Route
            element={
              <AuthWrapper>
                <MainLayout />
              </AuthWrapper>
            }
          >
            <Route path="/Dashboard" element={<ProfileProvider><RouteValidate slug={'Dashboard'}><Dashboard /></RouteValidate></ProfileProvider>} />
            <Route path="/ProfileApiUser" element={ <RouteValidate slug={'ProfileApiUser'}><ProfileApiUser /></RouteValidate>} />
            <Route path="/VanCollectionReport" element={ <RouteValidate slug={'VanCollectionReport'}> <VanCollectionReport /></RouteValidate> } />
            <Route path="/WhitelistIp" element={ <RouteValidate slug={'WhitelistIp'}> <WhitelistIp /></RouteValidate>} />
            <Route path="/WhitelistYourBankAc" element={ <RouteValidate slug={'WhitelistYourBankAc'}> <WhitelistYourBankAc /></RouteValidate>} />
            <Route path="/CommissionCharges" element={ <RouteValidate slug={'CommissionCharges'}> <CommissionCharges /></RouteValidate>} />
            <Route path="/AddFund" element={ <RouteValidate slug={'AddFund'}><AddFund /></RouteValidate>} />
            <Route path="/ApiLink" element={ <RouteValidate slug={'ApiLink'}> <ApiLink /></RouteValidate>} />
            <Route path="/TestAadhar" element={ <RouteValidate slug={'TestAadhar'}> <TestAadhar /></RouteValidate>} />
            <Route path="/TestPan" element={ <RouteValidate slug={'TestPan'}> <TestPan /></RouteValidate>} />
            <Route path="/HelpDesk" element={ <RouteValidate slug={'HelpDesk'}><HelpDesk /></RouteValidate>} />
            <Route path="/Crif_EquifaxReport" element={<RouteValidate slug={'Crif_EquifaxReport'}><Crif_EquifaxReport /></RouteValidate> } />
            <Route path="/EquifaxCrScore" element={ <RouteValidate slug={'EquifaxCrScore'}><EquifaxCrScore /></RouteValidate>} />
            <Route path="/HighmarkCrScore" element={ <RouteValidate slug={'HighmarkCrScore'}><HighmarkCrScore /></RouteValidate>} />
            <Route path="/BusinessWalletStructure" element={ <RouteValidate slug={'BusinessWalletStructure'}><BusinessWalletStructure /></RouteValidate> } />
            <Route path="/PayoutReport" element={ <RouteValidate slug={'BusinessWalletStructure'}> <PayoutReport /></RouteValidate> } />
            <Route path="/ServicePage" element={ <RouteValidate slug={'ServicePage'}><ServicePage /></RouteValidate> } />
            <Route path="/DiscriptionLink" element={ <RouteValidate slug={'DiscriptionLink'}> <DiscriptionLink /></RouteValidate>} />
            <Route path="/RechargeAndBBPSReport" element={ <RouteValidate slug={'DiscriptionLink'}><RechargeAndBBPSReport /></RouteValidate>} />
            
            <Route path="/LockScreen" element={ <LockScreen />} />
           
            <Route path="/RouteValidate" element={<RouteValidate />} />
          </Route>
        </Routes>
      </RoutesWrapper>


      
    </Router>



  );
}

function RoutesWrapper({ children }) {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 2 seconds

    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      {loading && <Loader />}
      {children}
    </>
  );
}

export default App;
