import React, { useState, useEffect ,Suspense} from 'react';
import { GiCash } from "react-icons/gi";
import './Dashboard.css';
import { PieChart } from 'react-minimal-pie-chart';
import SliderCrousel from '../../Components/SliderCrousel';
import Header from '../../Components/Header';
import Seo from '../../Components/SeoComponent/Seo';
import ModalWithPinInput from '../../Components/ModalWithPinComponent/DigitalKycAadhar';
import ModalWithPinInputEmail from '../../Components/ModalWithPinComponent/DigitalKycEmail'
import ModalWithPinInputMobile from '../../Components/ModalWithPinComponent/DigitalKycMobile';
import Confetti from 'react-confetti-boom';
import Webcam from 'react-webcam';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
  showSuccessToast,
  showErrorToast,
  showWarningToast,
} from "../../utils/toastUtils";
import { aadharverifyPService, fetchProfile, verifyEmail, verifyMobile, verifyPanService } from '../../Services/profile/profileservice';
import AnimateCard from './AnimateCard';
import { updatePin } from '../../Services/profile/profileservice';

// Lazy load all chart components
const MyLineChart = React.lazy(() => import('./MyLineChart'));
const MyLineChart2 = React.lazy(() => import('./MyLineChart2'));
const MyLineChart3 = React.lazy(() => import('./MyLineChart3'));
const MyLineChart4 = React.lazy(() => import('./MyLineChart4'));


export default function Dashboard() {

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setShowModal] = useState(false);
  const [isModalOpenEmail, setModalOpenEmail] = useState(false);
  const [isModalOpenMobile, setShowModalMobile] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const [isPanVerify, setIsPanVerify] = useState(false)
  const [isAadharVerify, setIsAadharVerify] = useState(false);
  const [isMobileVerify, setIsMobileVerify] = useState(false);
  const [isEmailVerify, setIsEmailVerify] = useState(false);
  const [clientID, setClientID] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [mobile, setMobile] = useState('');
  const [aadhar,setAadhar] = useState('');

  const toggleModal = () => setShowModal(!isModalOpen);

  const toggleModalMobile = () => {
    setShowModalMobile(prevState => !prevState);
  };

  // const webcamRef = useRef(null);
  // const [capturedImage, setCapturedImage] = useState(null);

  // const capture = useCallback(() => {
  //   setTimeout(() => {
  //     if (webcamRef.current) {
  //       const imageSrc = webcamRef.current.getScreenshot();
  //       console.log("Captured image:", imageSrc); // Debugging line
  //       if (imageSrc) {
  //         setCapturedImage(imageSrc);
  //       } else {
  //         console.log("Failed to capture image.");
  //       }
  //     }
  //   }, 500); // Delay to ensure video stream is ready
  // }, [webcamRef]);
    
  useEffect(() => {
    AOS.init({ duration: 1500, once: true });
  }, []);


  const toggleModalEmail = () => setShowModal(!isModalOpenEmail);

  // Validate Pan No from profile
  async function validatePan() {
    try {
      setIsPanVerify(true);  
      const response = await verifyPanService();
      
      if (response.status === 422) {
        showErrorToast(response.data.details.message);
        return;
      }
  
      if (response.status === 200 && response.status.code === 200) {
        showSuccessToast(response.status.message);
      } else {
        showWarningToast("Unexpected response status");
      }
    } catch (error) {
      showWarningToast("Failed to verify PAN");
      console.error("Error during PAN verification:", error);
    } finally {
      setIsPanVerify(false);
    }
  }
  
  // ENd


  // Validate Aadhar No from profile
  async function validateAadhar() {
    try {
      setIsAadharVerify(true);  // Show loading state
      const response = await aadharverifyPService();
  
      // Handle 422 validation error
      if (response.status.code === 422) {
        showErrorToast(response.status.message);
        return;
      }
      // Handle successful verification
      if (response.status.code === 200) {
        setClientID(response.data.data.client_id);
        setShowModal(true);
        getProfile();  // Assuming this fetches the user's updated profile
        showSuccessToast(response.status.message);
        return;
      }
      // Fallback if response status is unexpected
      showWarningToast("Unexpected response status");
    } catch (error) {
      // Handle API or network error
      showWarningToast("Failed to verify Aadhar");
      console.error("Aadhar verification error:", error);
    } finally {
      setIsAadharVerify(false);
    }
  }
  
  //  ENd

  // Validate Mobile No.  from profile
  async function validateMobile() {
    try {
      setIsMobileVerify(true);
      const response = await verifyMobile();
      if (response.status === 422) {
        showErrorToast(response.data.status.message);
        setIsMobileVerify(false);
        return
      }
      if (response.status.code === 200) {
        setIsMobileVerify(false);
        setIdentifier(response.identifier);
        setShowModalMobile(true);
        getProfile();
        showSuccessToast(response.status.message);
        return
      }
    } catch (error) {
      setIsMobileVerify(false);
      showWarningToast("Failed to verify mobile no");
      return error
    }
  }
  // ENd

  //    // Validate Email ID 
  async function validateEmail() {
    // try {
    //   setIsEmailVerify(true);
    //   const response = await verifyEmail();
    //   if(response.status===422){
    //     showErrorToast(response.data.status.message);
    //     setIsEmailVerify(false);
    //     return
    //   }
    //   if(response.status.code === 200){
    //     setIsEmailVerify(false);
    //     setModalOpenEmail(true);
    //     getProfile();
    //     console.log("");
    //     showSuccessToast(response.status.message);
    //     return
    //   }
    // } catch (error) {
    //   setIsEmailVerify(false);
    //   showWarningToast('Failed to verify Email Id');
    //   return error
    // }
  }


  // Fetch Profile Data that contain all Data
  const getProfile = async () => {
    try {
      const response = await fetchProfile();
      setMobile(response.data.mobile_number)
      setAadhar(response.data.aadhar_number)
     // console.log(response.data.mobile_number)
     // console.log(response.data.aadhar_number)
      setProfileData(response.data)
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    getProfile();
  }, [])
  // ENd


  return (
    <>
      <Seo />

      <ModalWithPinInput
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        title={`Enter OTP to Verify Aadhar  ****** ${aadhar?.substring(8,12)}`} 
        // title={`Enter OTP to Verify Mobile no. ****** ${mobile?.slice(-4)}`}
        clientID={clientID} // Handle aadhar submission
      />
      <ModalWithPinInputEmail
        isModalOpen={isModalOpenEmail}
        toggleModal={toggleModalEmail}
        title="Enter OTP to Verify Email"
      />
      <ModalWithPinInputMobile
        isModalOpenMobile={isModalOpenMobile}
        toggleModalMobile={toggleModalMobile}
        title={`Enter OTP to Verify Mobile no. ****** ${mobile?.substring(6,10)}`}
        identifier={identifier}    // Handel Mobile Submission
      />

<Header
  heading={profileData && profileData?.company_details 
    ? (
        <>
          <span className='font-semibold'>Welcome To</span> <span className=" font-extrabold">{profileData.company_details.company_name}</span>
        </>
      )
    : "Loading..."
  }
/>

<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 justify-center"  data-aos="fade-down">

  <AnimateCard  
  title="IDSPay Business Wallet
" text=" 9 3 8 5 4 7 6 3 3 4 7 8 "
text2="IDFC82673"
buttonview="view Statement"
checkbal="Check Balance"
/>

<AnimateCard  
  title="Collection Account
" text=" 3 8 6 7 3 5 6 3 4 2 8 7"
text2="SBIC83475"
buttonview="view Statement"
checkbal="Check Balance"
/>

<AnimateCard  
  title="Payout Account
" text=" 3 4 5 3 4 5 4 7 9 3 9 1"
text2="IFSc83475"
buttonview="view Statement"
checkbal="Check Balance"
/>
<div
  className="w-full h-48 md:h-full group overflow-hidden bg-black rounded-xl bg-gradient-to-tr from-blue-500 via-blue-900 to-blue-700 text-gray-50 relative bg-cover bg-center"
  style={{
    backgroundImage: "url('assets/images/HighMark.jpg')"
  }}
>
</div>



{/* <div className="w-full group">
<Confetti
    x={5}
    y={6}
    particleCount={100}
    deg={270}
    shapeSize={2}
    spreadDeg={35}
    effectInterval={2000}
    effectCount={3}
    colors={['#ff577f', '#ff884b', '#ffd384', '#fff9b0', '#3498db']}
    style={{ position: 'absolute', width: '100%', height: '100%' }}
  />
</div> */}


{/* 
<div className="w-full group overflow-hidden bg-black rounded-xl bg-gradient-to-tr from-blue-500 via-blue-900 to-blue-700 text-gray-50 relative">
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg" // Change to image/png if needed
        className="w-full h-full"
        videoConstraints={{
          width: 640,
          height: 480,
          facingMode: "user"
        }}
      />
      <button
        onClick={capture}
        className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white px-4 py-2 rounded-md"
      >
        Capture
      </button>

      {capturedImage && (
        <div className="mt-4">
          <h3>Captured Image:</h3>
          <img src={capturedImage} alt="Captured" className="rounded-md" />
        </div>
      )}
    </div> */}

</div>
      <div className="dashboard my-3 flex gap-2 flex-wrap" >

        <div className="flex flex-col lg:flex-row gap-2 w-full">
          <div className="box_shadow_box flex flex-col p-2 items-center justify-center lg:w-96" data-aos="fade-left">
            
            <img className='border_bottom' src={process.env.PUBLIC_URL + '/media/dashboard_img/employee_talking.png'} alt="" />
            <h1 className="text-center font-bold text-2xl my-2 text-gray-600">Business Banking Summary</h1>
            <div className="flex gap-3">
    
              <div className="box_without_box_shadow rounded-md m-2 flex flex-col p-2 bg-box-1">
                <GiCash />
                <h1>AePS Withdrawal</h1>
                <h1><strong className='text-2xl'>₹ 0.00</strong></h1>
              </div>
              <div className="box_without_box_shadow rounded-md m-2 flex flex-col p-2 bg-box-2">
                <GiCash />
                <h1>M-ATM Withdrawal</h1>
                <h1><strong className=' text-2xl'>₹ 0.00</strong></h1>
              </div>
            </div>
            <div className="flex gap-3">
              <div className="box_without_box_shadow rounded-md m-2 flex flex-col p-2 bg-box-3">
                <GiCash />
                <h1>DMT</h1>
                <h1><strong className=' text-2xl'>₹ 0.00</strong></h1>
              </div>
              <div className="box_without_box_shadow rounded-md m-2 flex flex-col p-2 bg-box-4">
                <GiCash />
                <h1>AEPS Settlement</h1>
                <h1><strong className=' text-2xl'>₹ 0.00</strong></h1>
              </div>
            </div>
          </div>
          <div className="txn_summary box_shadow_box p-2 flex-1" data-aos="fade-right">
            <div className="flex justify-between items-center">
              <h1 className="font-bold text-2xl my-2 text-gray-600 text-nowrap">Transaction Summary</h1>
            </div>
            <hr />
            <div className="flex justify-between items-center">
              <ul className='justify-center items-center  w-1/2'>
                <li className='flex items-center my-5 border-b-2 border-bottom border-b-yellow-500'>
                  <span className="dot bg-yellow-500 h-3 w-3 rounded-full mr-2"></span>
                  <div className="flex flex-col">
                    <strong className='text-gray-600'>Payout Pending ₹</strong>
                    <p className="text-gray-500">0.00</p>
                  </div>
                </li>
                <li className='flex items-center my-5 border-b-2 border-bottom border-b-green-500'>
                  <span className="dot bg-green-500 h-3 w-3 rounded-full mr-2"></span>
                  <div className="flex flex-col">
                    <strong className='text-gray-600'>Payout Success ₹</strong>
                    <p className="text-gray-500">0.00</p>
                  </div>
                </li>
                <li className='flex items-center my-5 border-b-2 border-b-red-500'>
                  <span className="dot bg-red-500 h-3 w-3 rounded-full mr-2"></span>
                  <div className="flex flex-col">
                    <strong className='text-gray-600'>Payout Failed ₹</strong>
                    <p className="text-gray-500">0.00</p>
                  </div>
                </li>
                <li className='flex items-center my-5 border-b-2 border-b-blue-900'>
                  <span className="dot bg-blue-900 h-3 w-3 rounded-full mr-2"></span>
                  <div className="flex flex-col">
                    <strong className='text-gray-600'>Money In ₹</strong>
                    <p className="text-gray-500">0.00</p>
                  </div>
                </li>
                <li className='flex items-center my-5 border-b-2 border-b-blue-500'>
                  <span className="dot bg-blue-500 h-3 w-3 rounded-full mr-2"></span>
                  <div className="flex flex-col">
                    <strong className='text-gray-600'>Money Out ₹</strong>
                    <p id='new_god' className="text-gray-500">0.00</p>
                  </div>
                </li>
              </ul>
              <div className='flex justify-around items-center flex-col min-h-80'>

                <select name="which_day" className='ml-3 border outline-none rounded' id="which_day">
                  <option value="Today">Today</option>
                  <option value="Yesterday">Yesterday</option>
                  <option value="This Month">This Month</option>
                  <option value="This Year">This Year</option>
                </select>
                <PieChart
                  style={{ height: '180px', width: '180px' }}
                  animate
                  animationDuration={2000}
                  animationEasing="ease-out"
                  data={[
                    { title: 'Payout Pending', value: 5000, color: '#eab308' },
                    { title: 'Payout Success', value: 10000, color: '#22c55e' },
                    { title: 'Payout Failed', value: 15000, color: '#ef4444' },
                    { title: 'Money In', value: 1000, color: '#1e3a8a' },
                    { title: 'Money Out', value: 20000, color: '#3b82f6' },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="box_shadow flex-1 card shadow-md p-2 rounded-sm" data-aos="zoom-in">
            <h1 className="font-bold text-2xl my-2 text-gray-600 text-nowrap px-5">New Launch</h1>
            <hr />
            <SliderCrousel />
          </div>
        </div>


        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 justify-center" data-aos="flip-left">
         {/* Suspense with a fallback UI while each chart is loading */}
    <Suspense fallback={<div>Loading chart 1...</div>}>
      <MyLineChart />
    </Suspense>

    <Suspense fallback={<div>Loading chart 2...</div>}>
      <MyLineChart2 />
    </Suspense>

    <Suspense fallback={<div>Loading chart 3...</div>}>
      <MyLineChart3 />
    </Suspense>

    <Suspense fallback={<div>Loading chart 4...</div>}>
      <MyLineChart4 />
    </Suspense>
        </div>

       
        <div className="box_shadow card shadow-md p-3 rounded-md w-full" data-aos="flip-right">
          <div className='flex justify-center'>
            <h1 className="font-bold text-2xl rounded-md p-1 text-white  bg-blue-600">Digital KYC</h1>
          </div>
          <hr />
          <div className="flex flex-wrap justify-center  gap-5 px-5 w-full">
            <div className="standard-box-shadow md:w-1/5 w-full bg-slate-300 rounded-md mt-2 box__ p-3">
              <div className="flex justify-between mb-3">
                <div className="left_icon bg-gray-200 p-2 rounded-full">
                  <img className='w-10 h-8' src="assets/images/aadhar.png" alt="aadhar" />
                </div>

              </div>
              <p className="text-xl mt-3 mb-2 font-bold">Aadhar</p>
              {
                profileData && profileData?.aadhar_number ? (
                  profileData?.aadhar_verified_at !== null && profileData?.aadhar_number?.length === 12 ? (
                    <p className='text-green-500 mt-6 font-bold text-xl'>Verified</p>
                  ) : profileData?.aadhar_number?.length !== 12 ? (
                    <p className='text-red-500 mt-6 font-bold text-md'>Invalid Aadhar number</p>
                  ) : (
                    <button
                    onClick={validateAadhar}
                    className={isAadharVerify ? "opacity-50 cursor-not-allowed mt-4 bg-green-800 hover:bg-blue-700 hover:text-white  text-white rounded-md p-1"
                      : 'bg-green-800 hover:bg-blue-700 hover:text-white   text-white rounded-md p-1 mt-4'} disabled={isAadharVerify}>{isAadharVerify ? 'Validating...'
                        : 'Validate'}
                  </button>
                  ) 
                  ) : (
                  <p className='text-red-500 mt-6 font-bold text-md'>Adhar number is missing</p>
                  )
              }
            </div>
            <div className="standard-box-shadow md:w-1/5 w-full bg-slate-300 rounded-md mt-2 box__ p-3">
              <div className="flex justify-between mb-3">
                <div className="left_icon bg-gray-200 p-2 rounded-full">
                  <img className='w-10 h-8' src="assets/images/credit-card.png" alt="pan" />
                </div>

              </div>
              <p className="text-xl mt-3 mb-2 font-bold">Pan</p>
              {
                profileData.length > 0 || profileData.
                  pan_verified_at !== null ?
                  <p className='text-green-500 mt-6 font-bold text-xl'>Verified</p>
                  :
                  <button
                    onClick={validatePan}
                    className={isPanVerify ? 'opacity-50 cursor-not-allowed mt-4 bg-green-800 hover:bg-blue-700 hover:text-white text-white rounded-md p-1'
                      : 'bg-green-800 hover:bg-blue-700 hover:text-white  text-white rounded-md mt-4 p-1'}
                    disabled={isPanVerify}>{isPanVerify ? 'Validating...' : 'Validate'}</button>
              }
            </div>
            <div className="standard-box-shadow md:w-1/5 w-full bg-slate-300 rounded-md mt-2 box__ p-3">
              <div className="flex justify-between mb-3">
                <div className="left_icon bg-gray-200 p-2 rounded-full">
                  <img className='w-10 h-8' src="assets/images/email.png" alt="email" />
                </div>
              </div>
              <p className="text-xl mt-3 mb-2 font-bold">Email</p>
              {
                profileData?.length > 0 || profileData.
                  email_verified_at !== null ?
                  <p className='text-green-500 mt-6 font-bold text-xl'>Verified</p>
                  :
                  <button onClick={validateEmail}
                    className={isEmailVerify ? 'opacity-50 cursor-not-allowed bg-green-800 mt-4 hover:bg-blue-700 hover:text-white text-white rounded-md p-1'
                      : 'bg-green-800 hover:bg-blue-700 hover:text-white  text-white rounded-md mt-4 p-1'
                    }>{isEmailVerify ? 'Validating...' : 'Validate'}</button>
              }
              {/* {profileData.email_address} */}
            </div>
            <div className="standard-box-shadow md:w-1/5 bg-slate-300 w-full rounded-md mt-2 box__ p-3">
              <div className="flex justify-between mb-3">
                <div className="left_icon bg-gray-200 p-2 rounded-full">
                  <img className='w-10 h-8' src="assets/images/mobile.png" alt="mobile" />
                </div>
              </div>
              <p className="text-xl mt-3 mb-2 font-bold">Mobile</p>
              {
       profileData && profileData?.mobile_number ? (
    profileData?.mobile_verified_at !== null && profileData?.mobile_number?.length === 10 ? (
      <p className='text-green-500 mt-6 font-bold text-xl'>Verified</p>
    ) : profileData?.mobile_number?.length !== 10 ? (
      <p className='text-red-500 mt-6 font-bold text-md'>Invalid mobile number</p>
    ) : (
      <button
        onClick={validateMobile}
        className={
          isMobileVerify
            ? 'opacity-50 cursor-not-allowed mt-4 bg-green-800 hover:bg-blue-700 hover:text-white text-white rounded-md p-1'
            : 'bg-green-800 hover:bg-blue-700 hover:text-white text-white rounded-md mt-4 p-1'}
        disabled={isMobileVerify}
      >
        {isMobileVerify ? 'Validating...' : 'Validate'}
      </button>
    )
  ) : (
    <p className='text-red-500 mt-6 font-bold text-md'>Mobile number is missing</p>
  )
}



            </div>

          </div>
        </div>
        <div className="box_shadow card shadow-md p-3 rounded-md w-full" data-aos="flip-left">
          <h1 className="font-bold text-2xl my-2 text-gray-600 text-nowrap px-5">Items</h1>
          <hr />
          <div className="grid gap-3 mt-3 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3  justify-center items-center">
            <div className="standard-box-shadow rounded-md overflow-hidden flex justify-between items m-auto">
              <div className="bg-blue-900 flex items-center justify-center p-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-phone" viewBox="0 0 16 16">
                  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                </svg>
              </div>
              <div className="p-3 w-100">
                <div className="flex justify-between w-56">
                  <h1 className="font-bold text-blue-900 text-lg">Recharge</h1>
                  <h1 className="text-gray-400 font-bold">Rs. 0.00</h1>
                </div>
                
              </div>
            </div>
            <div className="standard-box-shadow rounded-md overflow-hidden flex justify-between items m-auto">
              <div className="bg-blue-900 flex items-center justify-center p-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-phone" viewBox="0 0 16 16">
                  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                </svg>
              </div>
              <div className="p-3 w-100">
                <div className="flex justify-between w-56">
                  <h1 className="font-bold text-blue-900 text-lg">BBPS</h1>
                  <h1 className="text-gray-400 font-bold">Rs. 0.00</h1>
                </div>
                
              </div>
            </div>
            <div className="standard-box-shadow rounded-md overflow-hidden flex justify-between items m-auto">
              <div className="bg-blue-900 flex items-center justify-center p-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-phone" viewBox="0 0 16 16">
                  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                </svg>
              </div>
              <div className="p-3 w-100">
                <div className="flex justify-between w-56">
                  <h1 className="font-bold text-blue-900 text-lg">Pan Verification</h1>
                  <h1 className="text-gray-400 font-bold">Rs. 0.00</h1>
                </div>
                {/* <p className="font-bold text-blue-950">Total 68 Sold</p> */}
              </div>
            </div>
            <div className="standard-box-shadow rounded-md overflow-hidden flex justify-between items m-auto">
              <div className="bg-blue-900 flex items-center justify-center p-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-phone" viewBox="0 0 16 16">
                  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                </svg>
              </div>
              <div className="p-3 w-100">
                <div className="flex justify-between w-56">
                  <h1 className="font-bold text-blue-900 text-lg">Aadhar Verification</h1>
                  <h1 className="text-gray-400 font-bold">Rs. 0.00</h1>
                </div>
                {/* <p className="font-bold text-blue-950">Total 68 Sold</p> */}
              </div>
            </div>
            <div className="standard-box-shadow rounded-md overflow-hidden flex justify-between items m-auto">
              <div className="bg-blue-900 flex items-center justify-center p-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-phone" viewBox="0 0 16 16">
                  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                </svg>
              </div>
              <div className="p-3 w-100">
                <div className="flex justify-between w-56">
                  <h1 className="font-bold text-blue-900 text-lg">Credit Score</h1>
                  <h1 className="text-gray-400 font-bold">Rs. 0.00</h1>
                </div>
                {/* <p className="font-bold text-blue-950">Total 68 Sold</p> */}
              </div>
            </div>
            <div className="standard-box-shadow rounded-md overflow-hidden flex justify-between items m-auto">
              <div className="bg-blue-900 flex items-center justify-center p-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-phone" viewBox="0 0 16 16">
                  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                </svg>
              </div>
              <div className="p-3 w-100">
                <div className="flex justify-between w-56">
                  <h1 className="font-bold text-blue-900 text-lg">Mobile</h1>
                  <h1 className="text-gray-400 font-bold">Rs. 0.00</h1>
                </div>
                {/* <p className="font-bold text-blue-950">Total 68 Sold</p> */}
              </div>
            </div>
            <div className="standard-box-shadow rounded-md overflow-hidden flex justify-between items m-auto">
              <div className="bg-blue-900 flex items-center justify-center p-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-phone" viewBox="0 0 16 16">
                  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                </svg>
              </div>
              <div className="p-3 w-100">
                <div className="flex justify-between w-56">
                  <h1 className="font-bold text-blue-900 text-lg">Mobile</h1>
                  <h1 className="text-gray-400 font-bold">Rs. 0.00</h1>
                </div>
                {/* <p className="font-bold text-blue-950">Total 68 Sold</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

