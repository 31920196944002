import API_URLS from "../../config/BackendConfig";
import { getToken } from "../../utils/tokenUtils";
import { getRequest,postRequest } from "../../config/AxiosConfig";

const apiBaseUrl = API_URLS.CUSTOMER;

const getAuthHeaders = () => {
    const token = getToken();
    return token ? { Authorization: `Bearer  ${token}` } : {};
};

export const AddHighMarkData = async (formData) => {
  try {
    const payload = {
      api_id: formData.api_id,  // API ID
      api_key: formData.api_key,  // API Key
      token_id: formData.token_id,  // Token ID
      applicant_name: formData.applicant_name,  // Name
      dob: formData.dob,  // Date of Birth
      

      ids: formData.ids
      ?.filter(id => id.type && id.value)  // Only include IDs with both type and value
      .map(id => ({
        type: id.type,
        value: id.value
      })),

      // Phones array
      phones: formData.phones?.map(phone => ({
        type: phone.type || "MOB",
        number: phone.number
      })),

      // Address object
      address: {
        type: formData?.address?.type,
        address_1: formData?.address?.address_1,
        city: formData?.address?.city,
        state: formData?.address?.state,
        pin: formData?.address?.pin
      },

      // Loan details
      loan_details: {
        inquiry_unique_ref_no: formData.loan_details.inquiry_unique_ref_no,
        cred_rpt_id: formData.loan_details.cred_rpt_id,
        cred_req_typ: formData.loan_details.cred_req_typ,
        cred_rpt_trn_id: formData.loan_details.cred_rpt_trn_id,
        cred_inq_purps_typ: formData.loan_details.cred_inq_purps_typ,
        credit_inquiry_stage: formData.loan_details.credit_inquiry_stage,
        loan_amount: formData.loan_details.loan_amount,
        mbr_id: formData.loan_details.mbr_id,
        branch_id: formData.loan_details.branch_id,
        los_app_id: formData.loan_details.los_app_id
      },
      
      // Optional other details
      aadhar_no: formData.aadhar_no,
      pan_no: formData.pan_no,
      voter_id: formData.voter_id,
      ration_no: formData.ration_no,
      driving_licence: formData.driving_licence,
      passport: formData.passport,
      Gender: formData.Gender,
    };
    
    const response = await postRequest({
      url: "https://javabackend.idspay.in/api/v1/prod/crif-score/check",
      headers: getAuthHeaders(),
      data: payload
    });
   //console.log("HighMark Data Response:", response);
    return response;
  } catch (error) {
    console.error("Error while adding HighMark data:", error.response?.data || error.message);
    throw error;
  }
};


