import React, { useState, useEffect } from 'react';
import RouteValidatePin from '../../Pages/RouteValidate/RouteValidatePin';
import { ValidateRoutePrivacy } from '../../Services/RoutesServics/RoutesServics';
import { showErrorToast } from '../../utils/toastUtils';
import { useNavigate } from 'react-router-dom';


const RouteValidate = ({slug,children}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();


  const validateRoute = async () => {
    try {
      const payload = { route_url: slug }; // Adjust `route_url` as needed
      const response = await ValidateRoutePrivacy(payload);

      if (response?.status?.code === 200) {
        const { requires_pin, allowed } = response?.data || {};
        if (requires_pin && !allowed) {
          setIsModalOpen(true); // Open modal if PIN validation is required
        } else if (!requires_pin) {
          setError('PIN validation is not required for this route.');
          if(localStorage.getItem('isLock')===true){
            setIsModalOpen(true);
            return
          }
          navigate('');
        }
      } else {
        setError(response?.status?.message || 'Validation failed.');
      }
    } catch (err) {
      setError('Server error occurred while validating the route.');
      showErrorToast('Server error occurred while validating the route.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    validateRoute();
  }, [slug]);

  return (
    <div>
       { isModalOpen ? <RouteValidatePin isModalOpen={isModalOpen} toggleModal={()=>setIsModalOpen(false)} slug={slug}/> : [children] }
    </div>
  );
};

export default RouteValidate;
