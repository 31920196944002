import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import Seo from "../../Components/SeoComponent/Seo";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import { fetchProfile} from "../../Services/ApiTestService/aadharTestService";
import { verifyPan } from "../../Services/ApiTestService/panTestService";
import { InputWithLabel, Button } from "../../Components/Tags/Tags";
import Loader from "../../Loader";
import { Data2 } from "./Data2";

const TestPan = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [panData,setPanData] = useState({});
  const [errors, setErrors] = useState({});
  const [profileData, setProfileData] = useState({});
  const [panNumber, setPanNumber] = useState('');

  // Fetch Profile Data on Mount
  useEffect(() => {
    async function fetchProfileData() {
      try {
        setIsLoading(true);
        const response = await fetchProfile(); 
        setProfileData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching profile data", error);
        showErrorToast("Failed to fetch profile data.");
        setIsLoading(false);
      }
    }
    fetchProfileData();
  }, []);


  const validatePan = () => {
    const errors = {};
    if (!panNumber || panNumber.length !== 10) {
      errors.panNumber = "Please enter a valid Pan number.";
      showErrorToast(errors.panNumber = "Please enter a valid 10-digit Pan number.")
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };




  // Handle Pan number submission and OTP visibility
  const handleSubmit = async () => {
    if (!validatePan()) return;
    try {
      setIsLoading(true); // Start the loader
      const response = await verifyPan({
        api_id: profileData?.user_id,
        api_key: profileData?.api_key,
        token_id: profileData?.token_id,
        pan_number: panNumber,
      });
  
      //console.log(response);
  
      // Check for status 500
    //  console.log("1st",response.status.code);
      if (response?.status === 500) {
        setIsLoading(false); // Stop the loader
        showErrorToast(response.message || "Invalid PAN");
        return; // Exit the function early
      }
  
      // Check for status 200
     // console.log(response.status.code);
      if (response?.status?.code === 200) {
        if (response?.data?.data) {
         // console.log("API Response Data:", response?.data?.data);

          setPanData(response?.data?.data); // Set PAN data
          showSuccessToast(response?.message || "PAN verified successfully");
        } else {
          showErrorToast("PAN data is missing in the response.");
        }
        setPanNumber(''); // Clear the PAN number input
      }
  
      setIsLoading(false); // Ensure the loader is stopped
    } catch (error) {
      showErrorToast("Failed to verify PAN");
      setIsLoading(false); // Stop the loader in case of error
    }
  };
  

  return (
    <>
    <Seo />
    {isLoading && <Loader />}
    <Header heading="Test Pan API" />

    <div className="grid grid-cols-2 md:grid-cols-6 gap-3 justify-center md:ml-72 ml-0">
      <InputWithLabel
        type="text"
        label="Pan No"
        placeholder="Enter Pan No"
        maxLength={10} 
        className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
        name="pan"
        id="pan"
        required={true}
        value={panNumber}
        onChange={(e) => setPanNumber(e.target.value)}
        error={errors.panNumber}
      />

      {/* Verify button for Pan No */}
      <div>
        <Button
          type="button"
          className="text-center text-white bg-blue-900 shadow-lg bg-gradient-to-r from-blue-400 via-blue-1200 to-blue-900 font-semibold rounded-md px-2 py-1 mt-5 text-md hover:from-blue-900 hover:to-blue-400 border-4 ms-2"
          id="verifyPan"
          name="verifyPan"
          text="Verify"
         onClick={handleSubmit} 
        />
      </div>

    </div>

{panData && Object.keys(panData).length > 0 && (
  <>
    <div className="flex justify-center">
      <h2 className="font-bold text-2xl mt-4">Pan Details</h2>
    </div>
    <div className="border border-gray-300 rounded-lg p-4 mt-1 flex justify-center shadow-inner shadow-blue-600">
      <div className="grid grid-cols-2 md:grid-cols-4 text-lg gap-3">
        <div className="px-2">
          <span className="font-bold">Name:</span> {panData?.full_name || "N/A"}
        </div>
        <div className="px-2">
          <span className="font-bold">Pan Number:</span> {panData?.pan_number || "N/A"}
        </div>
        <div className="px-2">
          <span className="font-bold">Category:</span> {panData?.category || "N/A"}
        </div>
        {/* Add more fields if needed */}
      </div>
    </div>
  </>
)}

{panData && Object.keys(panData).length > 0 && (
  <div className="border border-gray-300 rounded-lg p-1 mt-2 shadow-inner shadow-blue-800">
    <h2 className="text-2xl font-bold mb-2 text-center">API Response</h2>
    <pre className="bg-slate-100 p-1 rounded-md overflow-auto text-green-600">
      {JSON.stringify(panData, null, 2)}
    </pre>
  </div>
)}
    </> 

  )
}

export default TestPan
