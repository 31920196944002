import React, { useState, useRef } from 'react';
import { verifyRoutePin } from '../../Services/RoutesServics/RoutesServics';
import { showErrorToast, showSuccessToast } from '../../utils/toastUtils';


const PinInput = ({ inputRefs, onPinChange }) => {
  const [pin, setPin] = useState(['', '', '', '', '', '']);

  const handleChange = (index, value) => {
    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);

    if (onPinChange) {
      onPinChange(newPin.join('')); // Pass the full PIN to onPinChange
    }

    if (value !== '' && index < 5) {
      inputRefs.current[index + 1].current.focus();
    }
  };

  const handleBackspace = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && pin[index] === '') {
      inputRefs.current[index - 1].current.focus();
    }
  };

  return (
    <div className="flex justify-center mb-4">
      {pin.map((value, index) => (
        <React.Fragment key={index}>
          <input
            ref={inputRefs.current[index]}
            type="password"
            maxLength={1}
            value={value}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleBackspace(index, e)}
            className="border border-blue-500 md:w-10 md:h-10 w-7 h-8 text-center mx-1 rounded shadow-lg shadow-blue-600"
            autoFocus={index === 0}
          />
          {index === 2 && <span className="mt-2 text-sky-500 ml-1">&ndash;</span>}
        </React.Fragment>
      ))}
    </div>
  );
};

const RouteValidatePin = ({ isModalOpen, toggleModal ,slug}) => {
    const inputRefs = useRef(Array.from({ length: 6 }).map(() => React.createRef()));
    const [pin, setPin] = useState('');
    const [error, setError] = useState('');
  
    const handleSubmit = async () => {
        if (pin.length !== 6) {
          setError('Please enter a 6-digit PIN.');
          return;
        }
      
        try {
          const result = await verifyRoutePin(pin);
        //  console.log(result)
          if (result?.status?.code===200) {
            // If PIN verification is successful
            showSuccessToast(result?.message);
            toggleModal(); 
            return
          } 
        
            // If PIN verification fails
            const errorMsg = result?.message || 'Invalid PIN. Please try again.';
            showErrorToast(errorMsg);
            setError(errorMsg);
          return
        } catch (err) {
         
          setError(err);
        }
      };
      
  
    return (
      isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" style={{ zIndex:999999}}>
          <div className="bg-white rounded-lg shadow-lg w-[70%] md:w-1/3 z-50">
            <div className="bg-[red] text-white text-center md:py-4 p-2 rounded-t-lg">
              <span className="md:text-xl text-md">Enter PIN to Verify & Access this Page</span>
            </div>
            <div className="p-4">
              <PinInput inputRefs={inputRefs} onPinChange={setPin} />
              {error && <div className="text-red-500 md:mt-2 text-center">{error}</div>}
            </div>
            <div className="flex justify-center md:p-6 p-1">
              <button
                type="button"
                className="text-center text-white bg-blue-900 shadow-xl shadow-blue-600 bg-gradient-to-r from-blue-400
                 to-blue-900 font-semibold rounded-md md:px-2 md:py-0 p-1 text-sm md:text-xl hover:from-blue-900
                  hover:to-blue-400 border-1"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )
    );
  };
  

export default RouteValidatePin;
