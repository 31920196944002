
import React, { useState, useEffect } from 'react';
import { DivTag , InputWithLabel, SelectWithLabelOption, Button} from '../../../Components/Tags/Tags';
import FormValidation from '../../../Components/FormValidation/FormValidation';
import Header from '../../../Components/Header';
import Seo from '../../../Components/SeoComponent/Seo';
import { EquifaxReportData } from '../../../Services/EquifaxCScore/equifax';
import { FaInbox } from 'react-icons/fa';

const Crif_EquifaxReport = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [EquifaxData, setEquifaxData] = useState([]);

const initialState = {
  from_date: "",
  to_date: "",
  total_data: "5",
  status: ""
};

const validate = () => {
  let errors = {};
  return errors;
};

const { values, errors, handleChange, handleBlur } = FormValidation(
  initialState,
  validate
);
const [pagination, setPagination] = useState({
  total: 0,
  per_page: 1,
  current_page: 1,
  last_page: 1,
});


  return (

    <>
      <Seo />
      <Header heading='Equifax Report' />
      <DivTag className="flex  sm:justify-center flex-wrap gap-4">
            <InputWithLabel
              DivclassName="w-1/2 md:w-1/6 px-2"
              label="From Date"
              type="date"
              name="from_date"
              id="from_date"
              value={values.from_date}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`border rounded-md py-2 px-3 ${errors.from_date ? "border-red-500" : "border-blue-600"
                }`}
              error={errors.from_date}
             />

              <InputWithLabel
              DivclassName="w-1/2 md:w-1/6 px-2"
              label="To Date"
              type="date"
              name="to_date"
              id="to_date"
              value={values.to_date}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`border rounded-md py-2 px-3 ${errors.to_date ? "border-red-500" : "border-blue-600"
                }`}
              error={errors.to_date}
            />
            
          </DivTag>
          <DivTag className="flex justify-center md:mt-3 md:mb-3">
            <Button
              type="button"
              onClick=""
              className="bg-gradient-to-r from-cyan-300 to-blue-900 ... text-white px-2 py-1 rounded disabled:opacity-50 border-sky-300 shadow-lg shadow-blue-500/50"
            >
              Search
            </Button>
      </DivTag>    
        
     
      <div className="overflow-x-auto my-1 p-2">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className=''>
              <tr className="bg-blue-700 text-white ">
                <th className="border-2 p-2">SNo.</th>
                <th className="border-2">Cus Name/ID</th>
                <th className="border-2">Mobile</th>
                <th className="border-2">D.O.B</th>
                <th className="border-2">Aadhar No.</th>
                <th className="border-2">Voter ID</th>
                <th className="border-2">Checked With Nominee Name</th>
                <th className="border-2">Customer Type</th>
                <th className='border-2'>Date</th>
                <th className='border-2'>Score</th>
                <th className='border-2'>Type</th>
                <th className="border-2">View Summary</th>
              </tr>
            </thead>
            <tbody>
              <tr key="" className='text-center'>
                  <td className="border shadow-inner shadow-blue-600 p-1">1</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">Namekr</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">5623445233</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">12/12/2000</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">9876557767655545</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">VT9878343</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">No</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">Regular</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">11/11/2001</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">674</td>
                  <td className='border shadow-inner shadow-blue-400 p-1'>Equifax</td>
                  <td className="border shadow-inner shadow-blue-400 p-1"><button className='bg-blue-700 text-white px-2 py-0 rounded-md'>View</button></td>
                  
                </tr>
                <tr key="" className='text-center'>
                  <td className="border shadow-inner shadow-blue-600 p-1">2</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">Namesks</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">59374343</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">10/10/2000</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">983453454545</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">VT97987343</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">Yes</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">CusType</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">11/11/2001</td>
                  <td className="border shadow-inner shadow-blue-400 p-1">674</td>
                  <td className='border shadow-inner shadow-blue-400 p-1'>Hymark</td>
                  <td className="border shadow-inner shadow-blue-400 p-1"><button className='bg-blue-700 text-white px-2 py-0 rounded-md'>View</button></td>
                </tr>
            </tbody>
          </table>
        </div>
    
    </>
  );
};


export default Crif_EquifaxReport;



