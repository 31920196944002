import API_URLS from "../../config/BackendConfig";
import { getToken } from "../../utils/tokenUtils";
import { getRequest,postRequest } from "../../config/AxiosConfig";

const apiBaseUrl = API_URLS.CUSTOMER;

const getAuthHeaders = () => {
    const token = getToken();
    // console.log('Token:', token);
    return token ? { Authorization: `Bearer ${token}` } : {};
  };

// Fetch City 
export const fetchCityService = async ({stateId}) => {
    try {
        const payload = {
            "stateId": stateId,
        }
        const response = await postRequest({
            url: apiBaseUrl + "fetch-city",
            headers: getAuthHeaders(),
            data: payload,
        })
        // console.log("cityy", response);
        return response;
    } catch (error) {
        console.error("error in fetching city", error.response?.data || error.message);
        throw error;
    }
};


// Fetch State 
export const fetchStateService = async () => {
    try {
        const response = await postRequest({
            url: apiBaseUrl + "fetch-state",
            headers: getAuthHeaders(),
        })
        // console.log("Statee", response);
        return response;
    } catch (error) {
        console.error("error in fetching State", error.response?.data || error.message);
        throw error;
    }
};


// Fetch Enquiry Code 
export const fetchEquifaxEQCodeService = async () => {
    try {
      
        const response = await postRequest({
            url: apiBaseUrl + "fetch-equifax-inquiry-code",
            headers: getAuthHeaders(),
        })
        // console.log("Equifax EQ Code", response);
        return response;
    } catch (error) {
        console.error("error in fetching Equifax EQ Code", error.response?.data || error.message);
        throw error;
    }
};


//Get Profile Data
export const fetchProfile = async () => {
    try {
      const response = await postRequest({
        url: apiBaseUrl + "profile",
        headers: getAuthHeaders(), // Ensure headers are correctly applied
      });
    //    console.log("data for Eqfax",response);
      return response;
    } catch (error) {
      console.error('Error fetching profile:', error.response?.data || error.message);
      throw error;
    }
  };



export const AddEquifaxData = async (formData) => {
    try {
      // The formData already contains the combined input fields and profile data
      const payload = {
        first_name: formData.first_name,
        api_id: formData.api_id,       // From profile
        api_key: formData.api_key,     // From profile
        token_id: formData.token_id,   // From profile
        dob: formData.dob,
        phones: formData.phones,
        aadhar_no: formData.aadharNo,
        pan_no: formData.panNo,
        voter_id: formData.voterID,
        ration_no: formData.rationCard,
        driving_licence: formData.drivingLicence,
        passport: formData.passport,
        inquiry_addresses: formData.inquiry_addresses,
        id_details: formData.id_details,
        mfi_details: formData.mfi_details,
        state: formData.state,
        city: formData.city,
        pin_code: formData.pinCode,
        nominee: formData.nominee,
        select_relation: formData.selectRelation,
        inquiry_purpose: formData.inquiry_purpose,
        reference_id: formData.reference_id
      };
  
      // Make API call
      const response = await postRequest({
        url: "https://javabackend.idspay.in/api/v1/prod/equifax-score/check",
        headers: getAuthHeaders(), // Assuming this is a helper function that adds authorization headers
        data: payload,
      });
  
      // console.log("Equifax Data Response:", response);
      return response;
    } catch (error) {
      console.error("Error while adding Equifax data:", error.response?.data || error.message);
      throw error;
    }
  };
  
// Equifax Reoprt 
export const EquifaxReportService = async () => {
    try {
       const response = await postRequest({
        url: apiBaseUrl + "equifax/report",
        headers: getAuthHeaders(),
       }) ;
    //    console.log("", response);
       return response
    } catch (error) {
        console.error("Erro Retriving Report", error.response?.data || error.message);
        throw error;
    }
};

export const CrifReportService = async () => {
  try {
    
  } catch (error) {
    
  }
}