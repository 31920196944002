
import React, { useEffect, useMemo, useState } from 'react';
import { DivTag, HeadTag, PTag, Button, InputField } from '../../Components/Tags/Tags';
import Header from '../../Components/Header';
import { retriveBankService, AddBankService, toggleBankService } from '../../Services/AddBankAC.js/Banks';
import { FaInbox } from 'react-icons/fa';
import moment from 'moment';
import Seo from '../../Components/SeoComponent/Seo';
import ConfirmPopUp from '../../Components/ConfirmPopUp/ConfirmPopUp';
import Loader from '../../Loader';
import { showSuccessToast,showErrorToast } from '../../utils/toastUtils';

const WhitelistYourBankAc = () => {
  
  const [ifsc, setIFSC] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [confirmAccountNo, setConfirmAccountNo] = useState('');
  const [validationError, setValidationError] = useState('');
  const [bankName, setBankName] = useState('');
  const [accoutType, setAccountType] = useState('');
  const [acHolderName, setAcHolderName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [bankData, setBankData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [paginationData, setPaginationData] = useState();


  const validateIFSC = () => {
    if (ifsc.length < 11) {
      setValidationError('IFSC code must be 11 char.');
      return false;
    } else {
      setValidationError('');
      return true;
    }
  };

  const validateAccountNo = () => {
    if (accountNo !== confirmAccountNo) {
      setValidationError('Account numbers do not match.');
      return false;
    } else {
      setValidationError('');
      return true;
    }
  };

  const handleVerifyAndAdd = async () => {
    await AddBankData();
    if (!validateIFSC() || !validateAccountNo() || !confirmAccountNo) {
      return;
    }
    setIsSuccess(true);
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setIFSC(value);
    setIsSuccess(false);
  };
  const handleAccountNoChange = (e) => {
    setAccountNo(e.target.value);
    setIsSuccess(false);
  };
  const handleConfirmAccountNoChange = (e) => {
    setConfirmAccountNo(e.target.value);
    setIsSuccess(false);
  };


 // Add Bank Data Function
  const AddBankData = async () => {
    setIsLoading(true);
    try {
      await AddBankService({
        account_number: confirmAccountNo,
        bank_name: bankName,
        account_type: accoutType,
        ifsc_code: ifsc,
        branch_name: branchName,
        account_holder_name: acHolderName,
      });

      resetForm();
      showSuccessToast("Bank data added successfully!");
      RetriveBankData();
    } catch (error) {
      console.error("Error adding bank data:", error);
      showErrorToast("Failed to add bank data.");
    } finally {
      setIsLoading(false);
    }
  };

  // Retrieve Bank Data Function
  const RetriveBankData = async (page = currentPage) => {
    setIsLoading(true);
    try {
      const response = await retriveBankService(page);
      setBankData(response?.accounts);
      setPaginationData(response?.pagination);
      setCurrentPage(response?.pagination?.current_page);
    } catch (error) {
      console.error("Error fetching bank data:", error);
      // showErrorToast("Failed to retrieve bank data.");
    } finally {
      setIsLoading(false);
    }
  };

  // Toggle Status Function
  const ToggleStatus = async () => {
    try {
      await toggleBankService({ account_id: selectedId });
      await RetriveBankData(currentPage);
      showSuccessToast("Status updated successfully!");
    } catch (error) {
      console.error("Error toggling status:", error);
      showErrorToast("Failed to toggle status.");
    } finally {
      closeModal();
    }
  };

  const openModal = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedId(null);
  };

  const resetForm = () => {
    setConfirmAccountNo("");
    setBankName("");
    setAccountType("");
    setIFSC("");
    setBranchName("");
    setAcHolderName("");
  };

  useEffect(() => {
    RetriveBankData();
  }, [currentPage]);


  return (
    <>
    <Seo />
    {isLoading && <Loader />}
    <ConfirmPopUp
        onConfirm={ToggleStatus}
        visible={showModal}
        onClose={closeModal}
        title="Are you Sure Want to Submit"
      />
      <DivTag>
        {/* <Loader /> */}
        <Header heading='WhiteList Bank A/c' />
        <DivTag className="container flex justify-center gap-1 flex-wrap">
          <DivTag>
            <HeadTag className="text-md" text="IFSC" />
            <InputField
              type="text"
              placeholder="Enter Ifsc"
              className="p-1 rounded shadow-lg shadow-blue-500/50 border border-blue-600"
              name="ifsc"
              id="ifsc"
              value={ifsc}
              onChange={handleInputChange}
              // onChange={(e) => setIFSC(e.target.value)}
              minLength={11}
              maxLength={11}
            />
            {/* {ifsc.length < 11 && <PTag className="text-red-500 font-sm" text={validationError} />} */}
          </DivTag>

          <DivTag>
            <HeadTag className="text-md" text="Bank Name" />
            <InputField
              type="text"
              className="p-1 rounded border border-blue-500 shadow-blue-500/50"
              placeholder="Bank Name"
              required={false}
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
            />
            <PTag className="text-center text-green-500" text="City, State" />
          </DivTag>
          <DivTag>
            <HeadTag className="text-md" text="Branch Name" />
            <InputField
              type="text"
              className="p-1 rounded border border-blue-500 shadow-blue-500/50"
              placeholder="Branch Name"
              required={false}
              value={branchName}
              onChange={(e) => setBranchName(e.target.value)}
            />
          </DivTag>

          <div className='md:w-1/6 w-1/2'>
            <label>Select Type</label>
            <select
              className=" py-1 px-7 md:mb-0 rounded-md border border-blue-600 shadow-blue-500/50"
              id="select_type"
              name="select_type"
              required={true}
              onChange={(e) => setAccountType(e.target.value)}
              value={accoutType}
            >
              <option value="" disabled selected>Select A/C Type</option>
              <option value="SAVING">SAVING</option>
              <option value="CURRENT">CURRENT</option>
            </select>
          </div>
          <DivTag>
            <HeadTag className="text-md" text="A/C Holder Name" />
            <input
              type="text"
              className="p-1 rounded shadow-lg border  border-blue-600 shadow-blue-500/50"
              placeholder="Holder Name"
              value={acHolderName}
              onChange={(e) => setAcHolderName(e.target.value)}
            />
          </DivTag>

          <DivTag>
            <HeadTag className="text-md" text="Account No." />
            <input
              type="text"
              className="p-1 rounded shadow-lg shadow-blue-500/50 hover:border-spacing-2 border border-blue-600"
              placeholder="Enter Account No."
              value={accountNo}
              onChange={handleAccountNoChange}
              minLength={8}
              maxLength={19}  
            />
          </DivTag>

          <DivTag>
            <HeadTag className="text-md" text="Confirm A/c No." />
            <input
              type="text"
              className="border p-1 rounded shadow-lg shadow-blue-500/50 hover:border-spacing-2 border-blue-900"
              placeholder="Enter Account No."
              value={confirmAccountNo}
              onChange={handleConfirmAccountNoChange}
              minLength={8}
              maxLength={19}
            />
            {/* {accountNo !== confirmAccountNo && <PTag className="text-red-500 font-sm" text="Account Numbers not Matched" />} */}
          </DivTag>
          <DivTag className="mt-5">
            <Button
              type="button"
              className="text-center text-white bg-blue-900 shadow-lg bg-gradient-to-r from-blue-400 via-blue-1200 to-blue-900 font-semibold rounded-md px-2 py-1 text-md hover:from-blue-900 hover:to-blue-400 border-4 ms-2"
              id="verifyadd"
              name="verifyadd"
              text="Verify & Add"
              onClick={handleVerifyAndAdd}
            />
            {isSuccess && <PTag className="text-green-600 mb-2" text="Successfully" />}
          </DivTag>
        </DivTag>

          
        {bankData && bankData?.length > 0 ? (
       <div className="overflow-x-auto my-1 p-2">
          <table id="table" className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr className='bg-blue-900 text-white text-center'>
                  <th className="border-2 p-2">SN</th>
                  <th className="border-2">A/c no.</th>
                  <th className="border-2">A/c holder name</th>
                  <th className="border-2">Ifsc</th>
                  <th className="border-2">Branch Details</th>
                  <th className="border-2">Add date</th>
                  <th className="border-2">Status</th>
                </tr>
              </thead>
              <tbody>
                { bankData.map((item, index) => (
                        <tr key={index} className='text-center'>
                          <td className="border p-1 shadow-inner shadow-blue-600">{index + 1}</td>
                          <td className="border p-1 shadow-inner shadow-blue-400">{item?.account_number}</td>
                          <td className="border p-1 shadow-inner shadow-blue-400">{item?.account_holder_name}</td>
                          <td className="border p-1 shadow-inner shadow-blue-400">{item?.ifsc_code}</td>
                          <td className="border p-1 shadow-inner shadow-blue-400">{item?.branch_name}</td>
                          <td className="border p-1 shadow-inner shadow-blue-400">{moment(item?.created_at).format('DD/MM/YYYY')}</td>
                          <td className="border p-1 shadow-inner shadow-blue-400">
                          <div className='flex justify-center gap-1'>
                        <p className='font-semibold'>Off</p><button className='border h-6 w-14 rounded-2xl bg-white  shadow-lg  border-sky-400 ' onClick={() => openModal(item.id)}>
                          <div className={item?.status?.value === "active" ? 'bg-green-500 translate-x-8 duration-200 transition-all rounded-full h-6 w-6' : "bg-red-500 rounded-full h-6 w-6 translate-x-0.1 duration-200 transition-all"} />
                        </button> <p className='font-semibold'>On</p></div>
                            </td>
                        </tr>
                  ))}
                  </tbody>
                </table>
                </div>
              ) : (
                <div className='flex items-center mt-4 gap-4 text-xl text-neutral-600 justify-center'>
                  <FaInbox className='text-blue-700' />
                  <p className='flex justify-center items-center'>Data not found</p>
                </div>
              )}
              <div className='gap-2 mt-3'>
                <div className='flex justify-center gap-3'>
                  <button className="py-1 text-xl rounded-md hover:bg-blue-800 px-4 bg-blue-500 text-white border-none cursor-pointer"
                   onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1} >Prev</button>
                  <button className="py-1 text-xl rounded-md hover:bg-blue-800 px-4 bg-blue-900 text-white border-none cursor-pointer"
                   onClick={() =>setCurrentPage((prev) => Math.min(prev + 1, paginationData?.last_page || 1))}
                    disabled={currentPage === paginationData?.last_page} >Next</button>
                  <div className='flex justify-end'>
                    <p className='text-[17px] font-bold'>Page {currentPage} of {lastPage}</p>
                  </div>
                </div>
              </div>
          </DivTag>
    </>);
};
export default WhitelistYourBankAc;














