import React, { useMemo, useState } from 'react';
import { fetchListOfService } from "../../Services/ServiceDocumentation/ServiceDoct";
import PlanCard from '../../Components/ServicesComponent/PlanCard';
import TestimonialCard from '../../Components/ServicesComponent/TestimonialCard';
import PopupModal from '../../Components/PopupModal';
import { DivTag, HeadTag, InputField, PTag, TextArea, Button, FormTag } from '../../Components/Tags/Tags';
import { IoMdClose } from "react-icons/io";
import Header from '../../Components/Header';

const ServicePage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [serviceData, setServiceData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useMemo(() => {
    const retrieveServiceData = async () => {
      try {
        setIsLoading(true);
        const response = await fetchListOfService(); 
        setServiceData(response?.data); // Store fetched data
        //console.log("id",response?.service_id);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching service data:", error);
        setIsLoading(false);
      }
    };

    retrieveServiceData();
  }, []);
  // ENd

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setIsSubmitted(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitted(true);
  };

  return (
    <>
      <PopupModal isOpen={isOpen} onClose={handleCloseModal}>
        <DivTag className="flex justify-end relative overflow-auto">
          
          <Button
            className=" bg-red-600 hover:bg-blue-500 rounded-lg text-white"
            onClick={handleCloseModal}
            icons={<IoMdClose  size={24}/>}
          /> 
        </DivTag>
        {isSubmitted ? (
          <PTag className="text-blue-500 font-bold text-xl p-5" text="Service activation request Successfully submitted"/>
        ) : (
          <FormTag onSubmit={handleSubmit}>
            <HeadTag className="text-xl font-bold mb-2 text-center" text="Activation Request" />
            <InputField
              type="text"
              id="domain_name"
              name="domain_name"
              placeholder="Domain Name"
              className="w-full p-2 mt-2 mb-4 border border-blue-700 rounded-md"
              onChange={() => {}}
            />
            <TextArea
              type="text"
              id='api_use_case'
              name='api_use_case'
              placeholder="API Use Case"
              className="w-full p-2 mb-4 border border-blue-700 rounded-md"
              value=""
              onChange={() => {}}
            />
            <DivTag className="flex justify-center">
              <Button
                type="submit"
                text="Submit"
                className="text-center text-white bg-blue-900 shadow-lg bg-gradient-to-r from-blue-400 via-blue-1200 to-blue-900 font-semibold rounded-md px-2 py-1 text-md hover:from-blue-900 hover:to-blue-400"
                id="Submit"
                name="Submit"
                onClick={() => {}}
              />
            </DivTag>
          </FormTag>
        )}
      </PopupModal>
      <Header heading='Service Page' />
        <DivTag className="flex md:flex-row flex-col jsutify-center gap-4 bg-state-300 overflow-hidden">
        <TestimonialCard />
        <DivTag className="w-full md:w-2/3 flex-wrap ">

<div className='grid grid-cols-1 md:grid-cols-3  mt-12 gap-y-4 overflow-y-scroll w-full'>
      {serviceData?.length > 0 && serviceData.map((service) => (
        <PlanCard 
          key={service?.service_id} 
          serviceId={service?.service_id}
          onButtonClick={handleOpenModal}
          serviceName={service?.service_name} 
          isAssigned={service?.assigned} 
        />
      ))}
    </div>

        </DivTag>
      </DivTag>
    </>
  );
};

export default ServicePage;

