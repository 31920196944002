import React, { useEffect, useState } from 'react';
import { Button } from '../../Components/Tags/Tags';
import Header from '../../Components/Header';
import Seo from '../../Components/SeoComponent/Seo';
import {RequstFundService , retriveFundService , SlipPreviewService} from '../../Services/AddBankAC.js/fundRequest';
import SlipViewModal from './SlipViewModal';
import { FaInbox } from 'react-icons/fa';
import moment from 'moment';
import {
  showSuccessToast,
  showErrorToast,
  showWarningToast,
} from "../../utils/toastUtils";
import Loader from '../../Loader';

const AddFund = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [paySlip, setPaySlip] = useState(null);
  const [amount, setAmount] = useState('');
  const [utrNo, setUtrNo] = useState('');
  const [txnDate, setTxnDate] = useState('');
  const [fundData, setFundData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [paginationData, setPaginationData] = useState();
  const [errors, setErrors] = useState({});
  const [filename, setFileName] = useState('');


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');


  const openModal = () => setIsModalOpen(true);

  const closeModal = () => {setFileName('')
    setImageUrl('')
     setIsModalOpen(false);}

  async function SlipPreview() {
    const response = await SlipPreviewService(filename);
    setImageUrl(response.dataUri)
    return
  }
  useEffect(() => {
    SlipPreview()
  }, [isModalOpen])

  const handleDateChange = (event) => {
    const dateValue = event.target.value;
    setTxnDate(dateValue);
  };

// Validate Form Input
const validateForm = () => {
  const newErrors = {};
  if (!paySlip) {
    newErrors.slip = 'PaySlip is required.';
  } else if (paySlip.size > 1024000) {
    // Check if paySlip file size exceeds 1 MB
    newErrors.slip = 'PaySlip must be smaller than 1 MB.';
  }
  if (!amount || isNaN(amount) || amount <= 0) newErrors.amount = 'Amount must be a Number.';
  if (!utrNo) newErrors.utrNo = 'UTR No is required.';
  if (!txnDate) newErrors.txnDate = 'Txn Date must be a valid date.';
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

  const handlesubmit = async () => {
    if (!validateForm()) return;

    try {
      setIsLoading(true);
      const txnDate2 = new Date(txnDate).toLocaleDateString('en-CA');
      const formData = new FormData();
      formData.append('slip', paySlip);
      formData.append('amount', amount);
      formData.append('utr', utrNo);
      formData.append('txn_date', txnDate2);

      const response = await RequstFundService(formData);
      if(response.status==422){
        return showErrorToast(response.data.errors.utr[0])
      }
    
      resetForm();
      showSuccessToast('Fund request added');
      setIsLoading(false);
    await  RetrieveFundData();
    return
    } catch (error) {
      setIsLoading(false);
      showErrorToast(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setPaySlip(null);
    setAmount('');
    setUtrNo('');
    setTxnDate('');
  };

    
   // Retrive Fund Data 
 const RetrieveFundData = async (page = currentPage) => {
    try {
      setIsLoading(true);
      const response = await retriveFundService(page);
    //  console.log(response);
      if (response && response.data) {
        setFundData(response.data.data || []);
        setPaginationData(response.data.pagination || {});
        setCurrentPage(response.data.pagination.current_page || 1);
        setLastPage(response.data.pagination.last_page || 1);
      }
    } catch (error) {
      showErrorToast(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    RetrieveFundData();
  }, [currentPage]);
  // ENd

  return (
         <>
            <Seo />
            {isLoading && <Loader />}
            <Header heading='Add Fund' />
            <div className="flex flex-wrap justify-center gap-2 md:mt-3">
  <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/5">
    <label htmlFor="payslip" className="block font-serif font-semibold">
      Upload PaySlip<span className="text-red-700 font-bold">*</span>
    </label>
    <input
      type="file"
      name="slip"
      id="slip"
      // accept=".jpeg, .png, .pdf, *"
      onChange={(e) => setPaySlip(e.target.files[0])}
      className="w-full border border-blue-600 rounded-md"
    />
    {errors.slip && <p className="text-red-600 text-sm font-mono">{errors.slip}</p>}
  </div>

  <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/5">
    <label htmlFor="amount" className="block font-serif font-semibold">
      Amount<span className="text-red-700 font-bold">*</span>
    </label>
    <input
      type="text"
      name="amount"
      id="amount"
      value={amount}
      onChange={(e) => setAmount(e.target.value)}
      placeholder="Enter Amount"
      className="w-full border border-blue-600 rounded-md py-1 placeholder:text-black"
    />
    {errors.amount && <p className="text-red-600 text-sm font-mono">{errors.amount}</p>}
  </div>

  <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/5">
    <label htmlFor="utr_no" className="block font-serif font-semibold">
      UTR No<span className="text-red-700 font-bold">*</span>
    </label>
    <input
      type="text"
      name="utrNo"
      id="utrNo"
      value={utrNo}
      onChange={(e) => setUtrNo(e.target.value)}
      placeholder="Enter UTR No."
      className="w-full border border-blue-600 rounded-md py-1 placeholder:text-black"
    />
    {errors.utrNo && <p className="text-red-600 text-sm font-mono">{errors.utrNo}</p>}
  </div>

  <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/5">
    <label htmlFor="txn_date" className="block font-serif font-semibold">
      Txn Date<span className="text-red-700 font-bold">*</span>
    </label>
    <input
      type="date"
      name="txnDate"
      id="txnDate"
      value={txnDate}
      onChange={handleDateChange}
      className="w-full border border-blue-600 rounded-md py-1"
    />

    {errors.txnDate && <p className="text-red-600 text-sm font-mono">{errors.txnDate}</p>}
  </div>

  <div className="w-full sm:w-auto">
    <Button
      color="primary"
      onClick={handlesubmit}
      disabled={isLoading}
      className="w-full sm:w-auto bg-gradient-to-r from-cyan-500 to-blue-800 text-white font-semibold rounded-md px-3 py-1 mt-7 shadow-lg hover:from-blue-900 hover:to-blue-400"
    >
      {isLoading ? 'Submitting...' : 'Submit'}
    </Button>
  </div>
</div>

   
      {fundData?.length > 0 ? (
        <div className="overflow-x-auto mt-6">
          <table className="min-w-full divide-y divide-gray-200 ">
            <thead className="bg-blue-600 text-white">
              <tr className='font-serif '>
                <th className="border border-white">SNo</th>
                <th className="p-2 border border-white">Request Date</th>
                <th className="p-2 border border-white">Status</th>
                <th className="p-2 border border-white">Remarks</th>
                <th className="p-2 border border-white">View</th>
                <th className='p-2 border border-white'>Balance</th>
                <th className="p-2 border border-white">UTR</th>
              </tr>
            </thead>
            <tbody className="text-center ">
              {fundData.map((item, index) => (
                <tr key={index} className='border-b-blue-900'>
                  <td className="p-1 border  shadow-inner shadow-blue-600 font-bold">{(currentPage - 1) * paginationData?.per_page + index + 1}</td>
                  <td className="p-1 border shadow-inner shadow-blue-400">{moment(item?.created_at).format('YYYY/MM/DD')}</td>
                  <td className="p-1 border shadow-inner shadow-blue-400">
                  <button
                   className={`px-1 py-1 text-white rounded ${
                   item?.status?.value === 'approved'
                   ? 'bg-green-600 first-letter:uppercase text-xs'
                    : item?.status?.value === 'pending'
                     ? 'bg-yellow-500 first-letter:uppercase text-xs'
                     : 'bg-red-500 first-letter:uppercase text-xs'
                        }`}
                       >
                     {item?.status?.value}
                      </button>
                      </td>
                  <td className="p-1 border shadow-inner shadow-blue-400">{item?.remarks}</td>
                  <td className="p-1 border shadow-inner shadow-blue-400"><button onClick={openModal} className="bg-green-500 text-white px-1 py-0 rounded-lg">Slip </button></td>
                  <td className='p-1 boder shadow-inner shadow-blue-400'>{item?.amount}</td>
                  <td className="p-1 border shadow-inner shadow-blue-400 ">{item?.transaction_ids?.length > 0 ? item?.transaction_ids[0]?.utr : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex flex-col items-center mt-5">
          <FaInbox className="text-3xl" />
          <p>No Fund Data Available</p>
        </div>
      )}

      {/* Pagination */}
      {lastPage > 1 && (
        <div className="flex justify-center mt-4">
          <button
            className={`px-3 py-1 bg-blue-600 text-white rounded-md hover:bg-blue-800 hover:text-white ${currentPage === 1 ? 'opacity-50' : ''}`}
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            Previous
          </button>
          <span className="mx-3">
            Page {currentPage} of {lastPage}
          </span>
          <button
            className={`px-3 py-1 bg-blue-600 text-white rounded-md hover:bg-blue-800 hover:text-white ${currentPage === lastPage ? 'opacity-50' : ''}`}
            disabled={currentPage === lastPage}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next
          </button>
        </div>
      )}
       <SlipViewModal isOpen={isModalOpen} onClose={closeModal} imageUrl={imageUrl} />
    </>
  );
};


export default AddFund


