import API_URLS from "../../config/BackendConfig";
import { getToken } from "../../utils/tokenUtils";
import { getRequest, postRequest } from "../../config/AxiosConfig";

const apiBaseUrl = API_URLS.CUSTOMER;

// Utility to get authorization headers
const getAuthHeaders = () => {
  const token = getToken();
  return token ? { Authorization: `Bearer ${token}` } : {};
};

// Fetch Profile Data
export const fetchProfile = async () => {
    try {
      const response = await postRequest({
        url: apiBaseUrl + "profile",
        headers: getAuthHeaders(), // Ensure headers are correctly applied
      });
      return response;
    } catch (error) {
      console.error('Error fetching profile:', error.response?.data || error.message);
      throw error;
    }
  };

// Generate OTP for Aadhar Send OTP
export const generateAadharOtp = async ({ api_id, api_key, token_id, aadhar, methodName}) => {
  try {
    const payload = {
       "api_id": api_id,
      "api_key": api_key,
      "token_id": token_id,
      "aadhar": aadhar,
      "methodName": methodName,
    };

    //console.log("Generating OTP with payload:", payload);

    const response = await postRequest({
        url: "https://javabackend.idspay.in/api/v1/prod/aadhar/verification",
      headers: getAuthHeaders(),
      data: payload,
    });

   // console.log("OTP Generation Response:", response);
    return response;
  } catch (error) {
    console.error("Error generating OTP:", error.response?.data || error.message);
    throw error;
  }
};

// Verify Aadhar 
export const verifyAadharWithOtp = async ({ api_id, api_key, token_id, aadhar, methodName, client_id, otp }) => {
  try {
    const payload = {
        "api_id": api_id,
        "api_key": api_key,
        "token_id": token_id,
        "aadhar": aadhar,
        "methodName": methodName,
        "client_id": client_id,
        "otp": otp,
    };

    //console.log("Verifying Aadhar with OTP using payload:", payload);

    const response = await postRequest({
        url: "https://javabackend.idspay.in/api/v1/prod/aadhar/verification",
      headers: getAuthHeaders(),
      data: payload,
    });

    //console.log("Aadhar Verification Response:", response);
    return response;
  } catch (error) {
    console.error("Error verifying Aadhar with OTP:", error.response?.data || error.message);
    throw error;
  }
};
