import API_URLS from "../../config/BackendConfig";
import { getToken } from "../../utils/tokenUtils";
import { getRequest, postRequest } from "../../config/AxiosConfig";

const apiBaseUrl = API_URLS.CUSTOMER;

const getAuthHeaders = () => {
    const token = getToken();
    return token ? { Authorization: `Bearer ${token}` } : {};
};

// Validate Route Privacy Service
export const ValidateRoutePrivacy = async ({ route_url }) => {
    try {
        const payload = { route_url };
        const response = await postRequest({
            url: `${apiBaseUrl}check-route-access`,
            headers: getAuthHeaders(),
            data: payload,
        });
        return response;
    } catch (error) {
        return {
            success: false,
            message: error.response?.data?.message || "Failed to validate route",
        };
    }
};

export const verifyRoutePin = async (pin) => {
    try {
        const response = await postRequest({
            url: `${API_URLS.CUSTOMER}validate-pin`,
            data: { pin }, 
            headers: getAuthHeaders(),
        });
        return response
    } catch (error) {
        const errorMessage =
            error.response?.data?.message || "Server error occurred while verifying PIN.";
        return {
            success: false,
            message: errorMessage,
        };
    }
};
