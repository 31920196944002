import React, { useEffect, useState } from 'react';
import {
    showSuccessToast,
    showErrorToast,
} from "../../utils/toastUtils";
import Header from '../../Components/Header';
import { fetchServiceDocumentation } from '../../Services/ServiceDocumentation/ServiceDoct';
import { Link } from 'react-router-dom';


const ApiLink = () => {
    const [documentationData, setDocumentationData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [serviceId, setServiceId] = useState(true);
    const search = window.location.search;
    // const serviceId = new URLSearchParams(search).get('serviceId');
    useEffect(() => {
        const search = window.location.search;
        const updatedServiceId = new URLSearchParams(search).get('serviceId');
        setServiceId(updatedServiceId);
    }, [search]);    

    //console.log("msg",serviceId)

    useEffect(() => {
        const getDocumentation = async () => {
            if (!serviceId) return; // Early return if serviceId is null
            try {
                const response = await fetchServiceDocumentation(serviceId);
              //  console.log("Data", response.data);
                setDocumentationData(response?.data);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
        getDocumentation();
    }, [serviceId]); // Trigger when serviceId changes
    

    // useEffect(() => {
    //     console.log("Current search:", search);
    //     console.log("Current serviceId:", serviceId);
    // }, [serviceId, search]);
    
    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!documentationData) {
        return <div>No documentation available.</div>;
    }


  // Construct the JSON payload dynamically
  const jsonPayload = JSON.stringify(
    {
        request: documentationData?.request || "No request provided",
        response: documentationData?.response || "No response provided",
        failed_response: documentationData?.failed_response || "No failed response provided",
    },
    null,
    2 // Pretty print with 2 spaces
);

const handleCopy = () => {
    // Create a temporary textarea to copy the text
    const tempTextarea = document.createElement('textarea');
    tempTextarea.value = jsonPayload;
    document.body.appendChild(tempTextarea);

    // Select and copy the text
    tempTextarea.select();
    document.execCommand('copy');

    // Remove the temporary textarea
    document.body.removeChild(tempTextarea);

    // Show success message
    showSuccessToast('Copied to clipboard!');
};

    

    return (
        <>
            <Header heading='ApiLink' />
            <div className='flex justify-center text-center'>
            <Link to="/ServicePage" className='font-bold text-2xl underline text-blue-700 animate-bounce' >Back to Service</Link>
            </div>
            <div className=" bg-gray-100 flex items-center justify-center">
                <div className="grid grid-col md:grid-cols-2 w-full overflow-x-auto overflow-y-auto ">
                    {/* First Half: Text Content */}
                    <div className="h-1/2 w-full bg-gray-200 p-1 overflow-y-scroll justify-center ">
                        <div className="p-4 bg-gray-100 rounded-lg shadow-md">
                            
                            <div className="mb-2">
                                <strong>Service ID:</strong> {documentationData?.service_id}
                            </div>
                            <div className="mb-2">
                                <strong>Endpoint:</strong> <a href={documentationData?.end_point} target="_blank" rel="noopener noreferrer">{documentationData.end_point}</a>
                            </div>
                            <div className="mb-2">
                                <strong>Introduction:</strong>
                                <div dangerouslySetInnerHTML={{ __html: documentationData?.introduction }} />
                            </div>
                            <pre className="text-black whitespace-pre-wrap">
                            <div className="mb-2">
                                <strong>Request:</strong> {documentationData?.request}
                            </div>
                            <div className="mb-2">
                                <strong>Response:</strong> {documentationData?.response || "No response provided"}
                            </div>
                            <div className="mb-2">
                                <strong>Failed Response:</strong> {documentationData?.failed_response}
                            </div>
                        </pre>
                        </div>
                    </div>

                    {/* Second Half: Form and JSON Payload */}
                    <aside className="h-1/2 w-full bg-black text-white p-4 rounded-lg font-mono overflow-y-scroll">
                        <div class="flex space-x-2 text-red-500 mb-3">
                            <div class="w-3 h-3 rounded-full bg-red-500"></div>
                            <div class="w-3 h-3 rounded-full bg-yellow-500"></div>
                            <div class="w-3 h-3 rounded-full bg-green-500"></div>
                        </div>
                        <label className="font-bold block mb-2">Endpoint</label>
                        <input type="text" value={documentationData?.end_point} className="w-full text-black rounded-md p-2 mb-4" />

                        <button className="text-xl mb-4 bg-white text-black rounded-md p-1 hover:bg-blue-800 hover:text-white">
                            Payload
                        </button>
                        <label className="font-bold block mb-2">Content Type</label>
                        <select className="w-1/2 rounded-md p-2 mb-4 text-black">
                            <option value="" disabled selected>application/json</option>
                        </select>

                        {/* Copy Button */}
                        <div className="flex justify-end">
                            <button
                                onClick={handleCopy}
                                className="bg-blue-500 text-white px-2 py-1 rounded-md mb-2 hover:bg-white hover:text-black"
                            >
                                Copy
                            </button>
                        </div>
                        {/* JSON Payload */}
                        <pre className="text-green-400 whitespace-pre-wrap">
                            <div className="mb-2">
                                <strong>Request:</strong> {documentationData?.request}
                            </div>
                            <div className="mb-2">
                                <strong>Response:</strong> {documentationData?.response || "No response provided"}
                            </div>
                            <div className="mb-2">
                                <strong>Failed Response:</strong> {documentationData?.failed_response}
                            </div>
                        </pre>
                        
                    </aside>
                </div>
            </div>
        </>
    );
};

export default ApiLink;



