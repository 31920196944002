import React,{useEffect} from 'react';
import './sidebar.css';
import { MdDashboard } from "react-icons/md";
import { FaCreditCard } from "react-icons/fa";
import { GiTakeMyMoney } from "react-icons/gi";
import { HiOutlineCurrencyRupee } from "react-icons/hi";
import ListSidebar from '../../Components/ListSidebar';
import { IoDocuments } from "react-icons/io5";
import { MdPayments } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { Link } from 'react-router-dom';
import Confetti from 'react-confetti-boom';
import { SiTestcafe } from "react-icons/si";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Sidebar() {
  function handleCloseTop() {
    const sidebarMain = document.querySelector('.siderbar_main');
    const mainComponent = document.querySelector('#main_component');
    var width = window.innerWidth;
    if (width <= 1024) {
     // console.log('1024 se kam')
      sidebarMain.classList.toggle('sidemar_main_3');
    } else {
      sidebarMain.classList.toggle('sidemar_main_2');
      mainComponent.classList.toggle('main_component2');
    }
  }

  useEffect(() => {
    AOS.init({ duration: 1500, once: true });
  }, []);

  return (
    <>
      <div className="items-center md:mt-0 mt-5 bg-gradient-to-br from-blue-800 to-blue-900 flex flex-col max-h-90vh h-screen max-w-max overflow-auto w-2/4 p-1 rounded siderbar_main" data-aos="flip-left">
        
        <div className='box-border relative flex flex-col max-w-max w-32 justify-center items-center'>
          <img className='animate-zoomInOut max-100' src={process.env.PUBLIC_URL + '/media/logo/idspay_circle_white.png'} alt="logo" />
          <div onClick={handleCloseTop} className="absolute close_top">
            <div
              className="flex cursor-pointer items-center justify-center text-3xl text-white caret-transparent"
            >
              <div
                className="group relative inline-flex w-24 items-center justify-center overflow-hidden rounded-full border-2 font-medium shadow-md h-5"
              >
                <span
                  className="ease absolute z-10 flex h-full w-full translate-y-full items-center justify-center rounded-full bg-[#d1b98a] text-white duration-300 group-hover:translate-y-0"
                ></span>
                <div
                  className="absolute z-50 flex h-full w-full items-center justify-center text-[#f6f2f2] group-hover:text-white"
                >
                  <svg
                    height="20px"
                    width="20px"
                    viewBox="0 0 72 72"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="white"
                      d="M 19 15 C 17.977 15 16.951875 15.390875 16.171875 16.171875 C 14.609875 17.733875 14.609875 20.266125 16.171875 21.828125 L 30.34375 36 L 16.171875 50.171875 C 14.609875 51.733875 14.609875 54.266125 16.171875 55.828125 C 16.951875 56.608125 17.977 57 19 57 C 20.023 57 21.048125 56.609125 21.828125 55.828125 L 36 41.65625 L 50.171875 55.828125 C 51.731875 57.390125 54.267125 57.390125 55.828125 55.828125 C 57.391125 54.265125 57.391125 51.734875 55.828125 50.171875 L 41.65625 36 L 55.828125 21.828125 C 57.390125 20.266125 57.390125 17.733875 55.828125 16.171875 C 54.268125 14.610875 51.731875 14.609875 50.171875 16.171875 L 36 30.34375 L 21.828125 16.171875 C 21.048125 15.391875 20.023 15 19 15 z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>

          </div>
        </div>
        <hr />
        <ul className="list-unstyled mt-1 items-center flex flex-col gap-2">
          
          <ListSidebar link="/" buttonText="Dashboard" icon={<MdDashboard />} />
          <li className="my-1 mx-2" data-aos="fade-up">
            <button className='_btn_ btn_with_submenu btn_submenu_2'>
              <div className="overflow-hidden h-9 text flex justify-left gap-2">
                <span className='relative top-1'>
                  <HiOutlineCurrencyRupee />
                </span>
                <div className='flex flex-col m-0 p-0'>
                  <span className='flex items-center font-bold'>
                    Payouts
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-down-short" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4" />
                    </svg></span>
                  <ul className='list-circle listInside mt-2'>
                    <li className='align-left py-1 my-1 li_hover'><Link to="">Initiate Payout</Link></li>
                    <li className='align-left py-1 my-1 li_hover'><Link to="/PayoutReport">Payout Report</Link></li>
                  </ul>
                </div>
              </div>
            </button>
          </li>
          <li className="my-1 mx-2" data-aos="fade-up">
            <button className='_btn_ btn_with_submenu btn_submenu_2'>
              <div className="overflow-hidden h-9 text flex justify-left gap-2">
                <span className='relative top-1'>
                  <GiTakeMyMoney />
                </span>
                <div className='flex flex-col m-0 p-0'>
                  <span className='flex items-center font-bold'>
                    Collect
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-down-short" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4" />
                    </svg></span>
                  <ul className='list-circle listInside mt-2 relative left_15'>
                    <li className='align-left py-1 my-1 li_hover'><Link to="/VanCollectionReport">Van Collection Report</Link></li>
                    <li className='align-left py-1 my-1 li_hover'><Link to="">UPI Collection report</Link></li>
                  </ul>
                </div>
              </div>
            </button>
          </li>
          <li className="my-1 mx-2" data-aos="fade-up">
            <button className='_btn_ btn_with_submenu btn_submenu_2'>
              <div className="overflow-hidden h-9 text flex justify-left gap-2">
                <span className='relative top-1'>
                  <FaCreditCard />
                </span>
                <div className='flex flex-col m-0 p-0'>
                  <span className='flex items-center  font-bold'>
                    Cards
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-down-short" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4" />
                    </svg></span>
                  <ul className='list-circle listInside mt-2'>
                    <li className='align-left py-1 my-1 li_hover'><Link to="">Prepaid Card</Link></li>
                    <li className='align-left py-1 my-1 li_hover'><Link to="">Expense Card</Link></li>
                  </ul>
                </div>
              </div>
            </button>
          </li>
          <li className="my-1 mx-2" data-aos="fade-up">
            <button className='_btn_ btn_with_submenu btn_submenu_2'>
              <div className="overflow-hidden h-9 text flex justify-left gap-2">
                <span className='relative top-1'>
                  <IoDocuments />
                </span>
                <div className='flex flex-col m-0 p-0'>
                  <span className='flex items-center font-bold'>
                    API Services
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-down-short" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4" />
                    </svg></span>
                  <ul className='list-circle listInside mt-2'>
                    <li className='align-left py-1 my-1 li_hover'><Link to="/ServicePage">Developers API</Link></li>
                    <li className='align-left py-1 my-1 li_hover'><Link to="/CommissionCharges">Commission & Charges</Link></li>
                    <li className='align-left py-1 my-1 li_hover'><Link to="/DiscriptionLink"> DiscriptionLink</Link></li>
                  </ul>
                </div>
              </div>
            </button>
          </li>

        <li className='my-1 mx-2' data-aos="fade-up">
          <button className='_btn_ btn_with_submenu btn_submenu_2'>
            <div className='overflow-hidden h-9 text flex justify-left gap-2'>
              <span className='relative top-1'>
                <IoDocuments />
              </span>
              <div className='flex flex-col m-0 p-0'>
                <span className='flex items-center font-bold'>
                  Credit Score
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-down-short" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4" />
                    </svg></span>
                <ul className='list-circle listInside mt-2'>
                  <li className='align-left py-1 my-1 li_hover'><Link to="/EquifaxCrScore">Eqifax Cr Score</Link></li>
                  <li className='align-left py-1 my-1 li_hover'><Link to="/HighmarkCrScore">Highmark Cr Score</Link></li>
                  <li className='align-left py-1 my-1 li_hover'><Link to="/Crif_EquifaxReport">Crif & Equifax Report</Link></li>
                </ul>
              </div>
            </div>
          </button>
        </li>

          <li className="my-1 mx-2" data-aos="fade-up">
            <button className='_btn_ btn_with_submenu btn_submenu_2'>
              <div className="overflow-hidden h-9 text flex justify-left gap-2">
                <span className='relative top-1'>
                  <MdPayments />
                </span>
                <div className='flex flex-col m-0 p-0'>
                  <span className='flex items-center font-bold text-sm'>
                    Recharge & BBPS
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-down-short" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4" />
                    </svg></span>
                  <ul className='list-circle listInside mt-2'>
                    <li className='align-left py-1 my-1 li_hover'><Link to="/RechargeAndBBPSReport">Recharge & BBPS Report</Link></li>
                  </ul>
                </div>
              </div>
            </button>
          </li>
          <li className="my-1 mx-2" data-aos="fade-up">
            <button className='_btn_ btn_with_submenu btn_submenu_1'>
              <div className="overflow-hidden h-9 text flex justify-left gap-2">
                <span className='relative top-1'>
                  <MdPayments />
                </span>
                <div className='flex flex-col m-0 p-0'>
                  <span className='flex items-center font-bold'>
                    API Setting
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-down-short" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4" />
                    </svg></span>
                  <ul className='list-circle listInside mt-2'>
                    <li className='align-left py-1 my-1 li_hover'><Link to="/WhiteListIP">Whitelist IP</Link></li>
                  </ul>
                </div>
              </div>
            </button>
          </li>

          <li className="my-1 mx-2" data-aos="fade-up">
            <button className='_btn_ btn_with_submenu btn_submenu_2'>
              <div className="overflow-hidden h-9 text flex justify-left gap-2">
                <span className='relative top-1'>
                <SiTestcafe />
                </span>
                <div className='flex flex-col m-0 p-0'>
                  <span className='flex items-center font-bold'>
                    Test API
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-down-short" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4" />
                    </svg></span>
                  <ul className='list-circle listInside mt-2 mb-3'>
                    <li className='align-left py-1 my-1 li_hover'><Link to="/TestAadhar">Aadhar Verification</Link></li>
                    <li className='align-left py-1 my-1 li_hover'><Link to="/TestPan">Pan Verification</Link></li>
                  </ul>
                </div>
              </div>
            </button>
          </li>







          <li className="my-1 mx-2" data-aos="fade-up">
            <button className='_btn_ btn_with_submenu btn_submenu_5'>
              <div className="overflow-hidden h-9 text flex justify-left gap-2">
                <span className='relative top-1'>
                  <CgProfile />
                </span>
                <div className='flex flex-col m-0 p-0'>
                  <span className='flex items-center font-bold'>
                    My Account
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-down-short" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4" />
                    </svg></span>
                  <ul className='list-circle listInside mt-2 mb-3'>
                    <li className='align-left py-1 my-1 li_hover'><Link to="/BusinessWalletStructure">Wallet Statement</Link></li>
                    <li className='align-left py-1 my-1 li_hover'><Link to="/WhitelistYourBankAc">Whitelist Bank A/c</Link></li>
                    <li className='align-left py-1 my-1 li_hover'><Link to="">AePS Settlement Report</Link></li>
                    <li className='align-left py-1 my-1 li_hover'><Link to="">Billing & Invoices</Link></li>
                    <li className='align-left py-1 my-1 li_hover'><Link to="/ProfileApiUser">My Profile</Link></li>
                    <li className='align-left py-1 my-1 li_hover mb-2'><Link to="/HelpDesk">Help Desk</Link></li>
                  </ul>
                </div>
              </div>
            </button>
          </li>

          
        </ul>
      </div>
    </>
  )
}