
import { getToken } from "../../utils/tokenUtils";
import { getRequest, postRequest } from "../../config/AxiosConfig";

// Utility to get authorization headers
const getAuthHeaders = () => {
  const token = getToken();
  return token ? { Authorization: `Bearer ${token}` } : {};
};

// Verify Aadhar 
export const verifyPan = async ({ api_id, api_key, token_id, pan_number}) => {
  try {
    const payload = {
        "api_id": api_id,
        "api_key": api_key,
        "token_id": token_id,
        "pan_number": pan_number
    };

    const response = await postRequest({
        url: "https://javabackend.idspay.in/api/v1/prod/pan/verification",
      headers: getAuthHeaders(),
      data: payload,
    });
     
  //  console.log("Pan Verification Response:", response);
    return response;
  } catch (error) {
    console.error("Error Pan Verfiy:", error.response?.data || error.message);
    throw error;
  }
};









